import { Card } from 'primereact/card';
import { Toolbar } from 'primereact/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeState } from '../../dtos/common/ThemeState';
import { Themes } from '../../data/Themes';
import { AppModeState } from '../../dtos/common/AppModeState';
import { AppMode } from '../../data/AppMode';
import '../../css/components/project.css';
import { getProjectByUser } from '../../service/project/ProjectService';
import { useEffect, useState } from 'react';
import { GetAllProjectItemsDto } from '../../dtos/project/GetAllProjectItemsDto';
import { GetAllProjectDto } from '../../dtos/project/GetAllProjectDto';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

export const Project = () => {
    //#region all Variables

    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);
    const dispatch = useDispatch();
    const localization = useSelector((state: any) => state.localization.localization);
    const [project, setProject] = useState<GetAllProjectItemsDto[]>([]);
    const [totalProjCount, setTotalProjCount] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');

    //#endregion 

    //#region all Functions

    const getProjectsOnLoad = async () => {
        const projects: GetAllProjectDto = await getProjectByUser(dispatch);
        const filteredProjects: GetAllProjectItemsDto[] = projects.items.filter(project => {
            return project.name.toLowerCase().includes(searchText.toLowerCase())
        })
        setProject(filteredProjects);
        setTotalProjCount(projects?.totalCount);
    }


    //#endregion 

    //#region all Templates

    const startToolbar = () => {
        return (
            <div>
                <h4>{localization?.ProjectList || 'Project List'}</h4>
                <p>{localization?.Viewallofyourprojectinformation || 'View all of your project information'}</p>
            </div>
        )
    };

    const endToolbar = () => {
        return (
            <div className="endToolbarPerformance">
                <InputText type="search" placeholder={localization?.Search || 'Search'} onChange={(e) => {
                    setTimeout(() => {
                        setSearchText(e.target.value)
                    }, 500)
                }} />
            </div>
        )
    };

    //#endregion 



    useEffect(() => {
        getProjectsOnLoad()
    }, [searchText])

    return (
        <div className='mb-3'>
            <Toolbar start={startToolbar} end={endToolbar} style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className='project-toolbar mb-3' />
            <Card style={{ backgroundColor: mode?.backgroundSecondaryColor }}>
                <DataTable
                    value={project}
                    totalRecords={totalProjCount}
                    showGridlines
                >
                    <Column bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} headerStyle={{ backgroundColor: ThemeColors?.primaryColor, color: 'white' }} field='name' header={localization?.Name || "Name"} sortable ></Column>
                </DataTable>
            </Card>
        </div>
    )
}