import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface props {
  data: { EmployeeName: string; EmployeeCode:number;BillingTimeInMinute: number; }[];
  mode?:{ name: string; backgroundPrimaryColor: string; backgroundSecondaryColor: string; color: string; }
}

const AllBilledEmpChart = (props: props) => {
  let empName: string[] = [];
  const billingHours: number[] = [];
  props.data.forEach((val) => {
    empName.push(`${val.EmployeeName} (${val.EmployeeCode})`);
    billingHours.push(val.BillingTimeInMinute / 60);
  });


  const baseHeight = 100; // Base height in pixels
  const additionalHeightPerDataPoint = 20; // Additional height per data point
  const dynamicHeight = props.data.length > 1 
  ? baseHeight + (props.data.length) * additionalHeightPerDataPoint 
  : 100;
  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      foreColor: props.mode?.color
    //   height: 550,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: empName,
      labels: {
        style: {
          colors: props.mode?.color || "#000",// X-axis labels color, fallback to black
        },
        
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: props.mode?.color || "#000",// Y-axis labels color, fallback to black
        },
      },
    },
    grid: {
      padding: {
        left: 50, // Adjust the padding to prevent labels from being cut off
        right: 25,
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(2)} hours`, // Format tooltip to show hours
      },
      theme: 'dark',
    },
  };
  const chartSeries = [
    {
      name: "Billing Time",
      data: billingHours,
    },
  ];

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={dynamicHeight}
      />
    </div>
  );
};

export default AllBilledEmpChart;
