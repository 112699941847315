import { Toolbar } from "primereact/toolbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { getSubordinatesByStatus } from "../../service/employee/employeeService";
import { EmployeeDto } from "../../dtos/employee/EmployeeDto";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import AssignSkillDialog from "./AssignSkillDialog";
import { Chip } from "primereact/chip";
import "../../css/components/goalTable.css";
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";

export const Employee = () => {
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [employee, setAllEmployee] = useState<EmployeeDto[]>([]);
  const dt = React.useRef<any>(null);
  const [searchText, setSearchText] = useState<string>("");
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const statusOptions = [
    { name: localization?.Active || "Active", value: "active" },
    { name: localization?.InActive || "Inactive", value: "inactive" },
    ,
    { name: localization?.All || "All", value: "all" },
  ];
  const [statusValue, setStatusValue] = useState(statusOptions[0]?.value);
  const [showAssignSkills, setShowAssignSkills] = useState<boolean>(false);
  const [showSkillDialog, setShowSkillDialog] = useState<boolean>(false);
  const [clickedShowSkillDialog, setClickedShowSkillDialog] = useState<
    number | null
  >(null);
 

  //#region all Functions

  const getAllEmp = async () => {
    const result: EmployeeDto[] = await getSubordinatesByStatus(dispatch, {
      Status: statusValue as string,
    });
    const filteredEmp = result?.filter((emp) => {
      return (
        emp?.fullName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        emp?.emailAddress?.toLowerCase().includes(searchText.toLowerCase()) ||
        emp?.employeeCode?.toLowerCase().startsWith(searchText?.toLowerCase())
      );
    });
    setAllEmployee(filteredEmp);
  };

  const handleStatusValue = (e: any) => {
    setStatusValue((prev) => {
      if (e.value === null) {
        return prev;
      } else {
        return e.value;
      }
    });
  };

  const showSkillsDialogHandler = (rowData: any) => {
    setShowSkillDialog(true);
    setClickedShowSkillDialog(rowData.id);
  };

  //#endregion

  //#region all Templates

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.Employee || "Employee"}</h4>
        <p>
          {localization?.ViewEmployeeInformation || "View employee information"}
        </p>
      </div>
    );
  };

  const skillTemplate = (rowData: any) => {
    return (
      <div>
        <FontAwesomeIcon
          icon={faAward}
          onClick={() => showSkillsDialogHandler(rowData)}
          className="skill-icon"
        />

        {showSkillDialog && clickedShowSkillDialog === rowData.id && (
          <Dialog
            header={localization?.Skills || "Skills"}
            visible={showSkillDialog}
            style={{
              width: "30vw",
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            contentStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            onHide={() => {
              if (!showSkillDialog) return;
              setShowSkillDialog(false);
              setClickedShowSkillDialog(null);
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {rowData.skills.length > 0
                ? rowData.skills.map(
                    (skill: { SkillName: string; GoalName: string }) => {
                      return (
                        <div
                          className="avatar-container"
                          style={{ marginRight: "12px" }}
                        >
                          <FontAwesomeIcon
                            icon={faAward}
                            className="skill-icon"
                            style={{
                              color: skill.GoalName ? "blue" : "#25ce25",
                              fontSize: "24px",
                            }}
                          />
                          <p className="skill-text">{skill.SkillName}</p>
                        </div>
                      );
                    }
                  )
                : localization?.NoSkillsAvailableForThisEmployee ||
                  "No skills available for this employee"}
            </div>
          </Dialog>
        )}
      </div>
    );
  };
  const endToolbar = () => {
    return (
      <div className="endToolbarPerformance">
        {employee.length > 0 && (
          <Button
            onClick={() => setShowAssignSkills(true)}
            style={{ fontWeight: "bold" }}
          >
            {localization?.AssignSkills || "Assign Skills"}
          </Button>
        )}
        <SelectButton
          value={statusValue}
          onChange={(e) => handleStatusValue(e)}
          options={statusOptions}
          optionLabel="name"
        />
        <InputText
          type="search"
          placeholder={localization?.Search || "Search"}
          onChange={(e) => {
            setTimeout(() => {
              setSearchText(e.target.value);
            }, 500);
          }}
        />
      </div>
    );
  };

  const activeBodyTemplate = (rowData: any) => {
    return (
      <Tag
        className={rowData?.isActive === true ? "activeIcon" : "inactiveicon"}
        value={
          rowData?.isActive === true ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            <FontAwesomeIcon icon={faXmark} />
          )
        }
        rounded
      ></Tag>
    );
  };

  const empRoleTemplate = (data: EmployeeDto) => {
    const { roleNames } = data;

    if (roleNames && roleNames.length > 1) {
      // If there are multiple roles, join them with commas
      const roles = roleNames.join(", ");
      return <span>{roles}</span>;
    } else if (roleNames && roleNames.length === 1) {
      // If there is only one role, display it without a comma
      return <span>{roleNames[0]}</span>;
    } else {
      // Handle the case when there are no roles
      return <span>No Role</span>;
    }
  };

  //#endregion
  useEffect(() => {
    getAllEmp();
  }, [searchText, statusValue]);

  return (
    <div>
      <Toolbar
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="mb-3"
      />
      <Card
        style={{ backgroundColor: mode?.backgroundSecondaryColor }}
        className="empolyee_table"
      >
        <DataTable ref={dt} dataKey="id" value={employee} showGridlines>
          <Column
            field="fullName"
            sortable
            header="Employee Name"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="Name_code"
          ></Column>
          <Column
            field="employeeCode"
            sortable
            header="Employee Code"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="emp_code"
          ></Column>
          <Column
            className="wrap_all"
            field="emailAddress"
            sortable
            header="Email ID"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>
          <Column
            body={empRoleTemplate}
            header="Role"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="emp_code"
          ></Column>
          <Column
            className="kpiColumn col_center col_150"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              textAlign: "center",
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="skills"
            header={`${localization?.Skills || "Skills"}`}
            body={skillTemplate}
          ></Column>
          <Column
            body={activeBodyTemplate}
            header="IsActive"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
              textAlign: "center",
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            className="emp_code emp_code_last"
          ></Column>
        </DataTable>
      </Card>
      <AssignSkillDialog
        showAssignSkills={showAssignSkills}
        setShowAssignSkills={setShowAssignSkills}
        getAllEmp={getAllEmp}
      />
    </div>
  );
};
