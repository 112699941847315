import { Card } from "primereact/card";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { MainPerformanceDto } from "../../dtos/performance/MainPerformanceDataDto";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CreatePerformanceDto } from "../../dtos/performance/CreatePerformanceDto";
import {
  createPerformance,
  getPerformanceWithKPIByEmployee,
} from "../../service/performance/PerformanceService";
import { useDispatch, useSelector } from "react-redux";
import { PerformanceInputDto } from "../../dtos/performance/PerformanceInputDto";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { GetOneUserDto } from "../../dtos/user/GetOneUserDto";
import "../../css/components/performanceView.css";
import "../../css/components/performance.css";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { setPath } from "../../store/slices/routePathSlice";
import { PerformanceOptionsDto } from "../../dtos/performance/PerformanceOptionsDto";
import { isNotUpcomingDate } from "../../shared/functions/IsNotUpcomingDateFunc";
import DatePicker from "react-datepicker";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import { Dialog } from "primereact/dialog";
import { getTheMonthName } from "../../shared/functions/GetMonthName";
import { AiFillDownCircle, AiFillRightCircle } from "react-icons/ai";
import { RadioButton } from "primereact/radiobutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { getPerformanceAndOption } from "../../service/ratingsAndReview/RatingServices";
import { GetAllSubKpiWithOptionsDto } from "../../dtos/subPerformance/GetAllSubKpiWithOptionsDto";
import { CreateSubPerformanceInputDto } from "../../dtos/subPerformance/CreateSubPerformanceInputDto";
import { OptionDetailsDto } from "../../dtos/subPerformance/OptionDetailsDto";
import { createSubPerformance } from "../../service/subPerformance/SubPerformanceServices";
import { ISkippedSubKpiDto } from "../../dtos/subKpiOptions/ISkippedSubKpiDto";
import { getAllSubPerformanceValues } from "../../service/subkpi/subKpiService";
import { GetSubKpisWithValueInputDto } from "../../dtos/subPerformance/GetSubKpisWithValueInputDto";
import { GetSubKpisWithValueDto } from "../../dtos/subPerformance/GetSubKpisWithValueDto";
import { ProgressBar } from "primereact/progressbar";
import { getUserRole } from "../../service/user/userService";
import {
  performanceDate,
  performanceEmpCode,
} from "../../shared/constant/Common";

interface PerformanceElement {
  optionId: number;
  optionName: string;
}

export const PerformnaceView = () => {
  //#region all Variables
  const props = useSelector((state: any) => state.performanceViewInput);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<MainPerformanceDto>(props.data);
  const [activeIndex, setActiveIndex] = useState<number[]>([0]);
  const [date, setDate] = useState<any>(props?.date);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors: any = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [reviewMode, setReviewMode] = useState(props.mode);
  const toast = useRef<any>(null);
  const [disableButtons, setDisableButtons] = useState(
    props.data.IsSubmitted === undefined ? true : props.data.IsSubmitted
  );
  const [disableRadioButtons, setDisableRadioButtons] = useState(
    props.data.IsSubmitted
  );
  const [score, setScore] = useState<number>(
    props.data.AverageRating === null ? 0 : props.data.AverageRating
  );
  const isAdmin = localStorage.getItem("role");
  const [isOpenAdminReason, setIsOpenAdminReason] = useState<boolean>(false);
  const loggedInUserRole: string | null = localStorage.getItem("role");
  const [isVisibleChangeReson, setIsVisibleChangeReson] =
    useState<boolean>(false);
  const [changeReasonVal, setChangeReasonVal] = useState<string>("");
  const [questionData, setQuestionData] = useState<PerformanceElement[]>([]);
  const [performance, setPerformance] = useState<GetAllSubKpiWithOptionsDto[]>(
    []
  );
  const [performanceLoading, setPerformanceLoading] = useState<boolean>(false);
  const [allAnswersByUser, setAllAnswerByUser] = useState<any>({});
  const [selectedQuestion, setSelectedQuestion] =
    useState<GetAllSubKpiWithOptionsDto>();
  const [buttonColors, setButtonColors] = useState<{ [key: string]: string }>(
    {}
  );
  const [visitedQuestions, setVisitedQuestions] = useState<{
    [key: string]: boolean;
  }>({});
  const [skpippedSubKpis, setSkpippedSubKpis] = useState<ISkippedSubKpiDto[]>(
    []
  );
  const [loggedInUser, setLoggedInUser] = useState<GetOneUserDto | null>(null);
  const [allKpiIds, setAllKpiIds] = useState<number[]>([]);
  const [role, setRole] = useState("");
  const location = useLocation();

  //#endregion
  //#region all Functions
  const fetchRole = async () => {
    const roleData = await getUserRole(dispatch);
    setRole(roleData);
  };

  const handleRoute = () => {
    localStorage.setItem("lastRoute", "/performance");
    navigate("/performance");
    dispatch(setPath({ path: "/performance" }));
  };

  const handlePrevMonth = () => {
    if (date) {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay.getMonth() - 1);
      setDate(firstDay);
      localStorage.setItem(performanceDate, firstDay.toString());
    }
  };

  const fetchLoggedInUserData = async () => {
    const res = await LoggedInUserData(dispatch);
    setLoggedInUser(res);
  };

  const handleNextMonth = () => {
    if (date) {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      console.log(firstDay);
      firstDay.setMonth(firstDay?.getMonth() + 1);

      //if the date is upcoming date then set it current date
      if (!isNotUpcomingDate(firstDay.toString())) {
        setDate((prev: any) => {
          const newDate = prev;
          localStorage.setItem(performanceDate, newDate);
          return newDate;
        });

        toast.current.show({
          severity: "warn",
          summary: "Sorry!",
          detail: "You can't go to the current and upcoming months!",
          life: 3000,
        });
        return;
      }

      setDate(firstDay);
      localStorage.setItem(performanceDate, firstDay.toString());
    }
  };

  const inputValidation = (
    input: PerformanceInputDto[],
    isSubmitted: boolean
  ) => {
    let validationRes: boolean[] = [];

    if (!isSubmitted) {
      input.forEach((val) => {
        if (val.Rating !== null) {
          validationRes.push(true);
        } else {
          validationRes.push(true);
        }
      });
    } else {
      input.forEach((val) => {
        if (val.Rating == null) {
          validationRes.push(false);
        } else {
          validationRes.push(true);
        }
      });
    }
    return validationRes;
  };

  const handleScoreCalculation = (KPIs: PerformanceOptionsDto[]) => {
    let score: number;

    const totalRating = KPIs.reduce((acc, kpi) => {
      return acc + kpi.rating * kpi.weightage;
    }, 0);
    const totalWeightage = KPIs.reduce((acc, kpi) => {
      return acc + kpi.weightage;
    }, 0);

    score = totalRating / totalWeightage;

    return score;
  };

  //handle date input
  const handleDate = (e: any) => {
    //if the date is upcoming date then set it current date
    if (!isNotUpcomingDate(e)) {
      setDate((prev: any) => {
        const newDate = prev;
        return newDate;
      });
      toast.current.show({
        severity: "warn",
        summary: "Sorry!",
        detail: "You can't go to the current and upcoming months!",
        life: 3000,
      });
      return;
    }
    setDate(e);
    localStorage.setItem(performanceDate, e);
  };

  const accept = async (isSubmitted: boolean) => {
    const inputForAllSubPerformanceVal: GetSubKpisWithValueInputDto = {
      submittedDate: ISTToDate(date) as string,
      kpiIds: allKpiIds,
      employeeCode: props.employeeCode,
    };
    const allSubPerformanceVal: GetSubKpisWithValueDto[] =
      await getAllSubPerformanceValues(dispatch, inputForAllSubPerformanceVal);

    data?.KPIs?.forEach((kp) => {
      const presentedPerformance: GetSubKpisWithValueDto | undefined =
        allSubPerformanceVal.find((val) => val.KpiName == kp.kpiName);
      if (presentedPerformance?.Value) {
        kp.rating =
          Math.round((presentedPerformance.Value / presentedPerformance.Score) * 100);
      }
    });

    //if change reason then open the dialog
    if (isOpenAdminReason && !isVisibleChangeReson) {
      setIsVisibleChangeReson(true);
      return;
    }

    const input: PerformanceInputDto[] = [];
    const userDetails: GetOneUserDto = loggedInUser as GetOneUserDto;

    data?.KPIs.forEach((kp) => {
      const inputVal: PerformanceInputDto = {
        KPIID: kp.kpiId,
        Rating: kp.rating,
        Review: kp.review,
        ReviewerID: userDetails?.EmployeeCode,
        KPIName: kp.kpiName.toLowerCase(),
        Weightage: kp.weightage,
        Target: kp.target,
      };
      input.push(inputVal);
    });

    if (data) {
      const performanceInput: CreatePerformanceDto = {
        EmployeeCode: props?.employeeCode,
        SubmittedDate: ISTToDate(date) as string,
        AdminReason: changeReasonVal,
        ReviewerID: userDetails?.EmployeeCode,
        IsSubmitted: isSubmitted,
        Input: input,
      };
      const checkValidation = inputValidation(
        performanceInput.Input,
        isSubmitted
      );

      if (!checkValidation.includes(false)) {
        //if admin wants to change the score and set reason
        await createPerformance(dispatch, performanceInput);
        setScore(handleScoreCalculation(data?.KPIs));
        await handleData();
        if (isSubmitted) {
          setDisableButtons(true);
          if (loggedInUserRole !== null && loggedInUserRole === "admin") {
            setIsOpenAdminReason(false);
          }
          toast.current.show({
            severity: "success",
            summary: "Submitted",
            detail: "You have successfully submitted the form",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Submitted",
            detail: "You have successfully save all the performance",
            life: 4000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please provide valid rating value.",
        });
      }
    }
  };

  const transformData = (data: GetAllSubKpiWithOptionsDto[]) => {
    const result: any = [];

    data?.forEach((subkpi: GetAllSubKpiWithOptionsDto, subKpiIndex: number) => {
      subkpi?.options?.forEach(
        (option: OptionDetailsDto, optionIndex: number) => {
          const transformedObject = {
            optionId: `${subKpiIndex}-${optionIndex}`,
            optionName: option.Label,
          };
          result.push(transformedObject);
        }
      );
    });

    return result;
  };

  const handleData = async () => {
    const loggedInEmpCode = await LoggedInUserData(dispatch);
    if (date) {
      const input = {
        submittedDate: ISTToDate(date),
        empCode: params.empId,
        loggedInEmpCode: loggedInEmpCode?.EmployeeCode,
        roleName: props.roleName,
      };
      const res = await getPerformanceWithKPIByEmployee(dispatch, input);
      if (res) {
        const kpiIds: number[] = [];
        res.KPIs.forEach((kpi: any) => kpiIds.push(kpi.kpiId));
        setAllKpiIds(kpiIds);
        const updatedData = await getTotalSubPerformanceVal(res);
        setData(updatedData);
        setChangeReasonVal(res.AdminReason);
        console.log("issubmitted button",res.IsSubmitted)
        setDisableButtons(
          res.IsSubmitted === undefined ? true : res.IsSubmitted
        );
        setDisableRadioButtons(res.IsSubmitted);
        setScore(res.AverageRating === null ? 0 : res.AverageRating);
        if (res.IsSubmitted) {
          setReviewMode("view");
        } else {
          setReviewMode("review");
        }
      }
    }
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected to save the form",
      life: 3000,
    });
  };

  const handleAdminReasonBtn = async () => {
    // if no reason is written
    if (changeReasonVal === null) {
      toast.current.show({
        severity: "warn",
        summary: "warning!",
        detail: "Please provide reason for change the score!.",
      });
      return;
    }

    if (changeReasonVal.length === 0) {
      toast.current.show({
        severity: "warn",
        summary: "warning!",
        detail: "Please provide reason for change the score!.",
      });
      return;
    }

    if (isOpenAdminReason && changeReasonVal?.trim().length === 0) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Space should not be accepted in the comments field!",
      });
      return;
    }

    await accept(true);
    setIsVisibleChangeReson(false);
    setIsOpenAdminReason(false);
  };

  const getPerformance = async (employeeCode: string, kpiId: number,kpiName:string) => {
    const res = await getPerformanceAndOption(dispatch, {
      kpiId,
      kpiName,
      employeeCode,
      submittedDate: ISTToDate(date),
    });
 
    return res;
  };

  const getSubKpiPerformanceData = async (kpiid: number,kpiName:string) => {
    setPerformanceLoading(true);
    const response: GetAllSubKpiWithOptionsDto[] = await getPerformance(
      props?.employeeCode,
      kpiid,
      kpiName
    );
    response.forEach((subkpi) => {
      setAllAnswerByUser((prev: any) => {
        return {
          ...prev,
          [subkpi.subKpiName]: subkpi.value,
        };
      });
    });
    setPerformance(response);
    setQuestionData(transformData(response));
    setPerformanceLoading(false);
  };

  const getTotalSubPerformanceVal = async (performance: any) => {
    const inputForAllSubPerformanceVal: GetSubKpisWithValueInputDto = {
      submittedDate: ISTToDate(date) as string,
      kpiIds: allKpiIds,
      employeeCode: props?.employeeCode,
    };

    if (allKpiIds.length == 0) {
      const kpiIds: number[] = [];
      performance.KPIs.forEach((kpi: any) => kpiIds.push(kpi.kpiId));
      inputForAllSubPerformanceVal.kpiIds = kpiIds;
    }

    const allSubPerformanceVal: GetSubKpisWithValueDto[] =
      await getAllSubPerformanceValues(dispatch, inputForAllSubPerformanceVal);
    const updatedData = {
      ...performance,
      KPIs: performance?.KPIs?.map((kpi: any) => {
        const isPresentVal = allSubPerformanceVal?.find(
          (val) => val.KpiName.toLowerCase() === kpi.kpiName.toLowerCase()
        );
        if (isPresentVal) {
          return {
            ...kpi,
            CompletedSubPerformanceCount:
              isPresentVal.CompletedSubPerformanceCount,
            SkippedSubPerformanceCount: isPresentVal.SkippedSubPerformanceCount,
            TotalSubKpiCount:
              isPresentVal.CompletedSubPerformanceCount +
              isPresentVal.SkippedSubPerformanceCount,
          };
        }
        return kpi;
      }),
    };
    return updatedData;
  };

  const handleTabChange = async (kpiid: number,kpiName:string) => {
    let isOpen = false;
    if (activeIndex.includes(kpiid)) {
      setActiveIndex([0]);
      isOpen = false;
    } else {
      setActiveIndex([kpiid]);
      isOpen = true;
    }

    if (isOpen) {
      await getSubKpiPerformanceData(kpiid,kpiName);
    }
  };

  const handleSkippedSubKpi = (subKpiId: number, isSkipped: boolean) => {
    const isPresentInSkippedData = skpippedSubKpis.findIndex(
      (val) => val.subKpiId === subKpiId
    );

    if (isPresentInSkippedData !== -1) {
      skpippedSubKpis.splice(isPresentInSkippedData, 1);
    }
    setSkpippedSubKpis((prev) => [
      ...prev,
      { subKpiId: subKpiId, isSkipped: isSkipped },
    ]);
  };

  const handleAnswerChange = async (input: CreateSubPerformanceInputDto) => {
    setAllAnswerByUser((prevAnswers: any) => ({
      ...prevAnswers,
      [(selectedQuestion as GetAllSubKpiWithOptionsDto)?.subKpiName]: input.Value,
    }));
    const createdSubPerformance = await createSubPerformance(dispatch, input);
    const updatedData = await getTotalSubPerformanceVal(data);
    setData(updatedData);

    if (createdSubPerformance.isAllSubKpiSubmitted) {
      //if all the subperformaces are done then call save performance data
      await accept(false);
      await handleData();
      toast.current.show({
        severity: "success",
        summary: "Congratulation!",
        detail:
          "You have saved all the scores now you can sumbit scores by clicking Submit button!",
        life: 3000,
      });
    }
  };

  const handlePrevious = () => {
    performance.forEach((question, i) => {
      if (selectedQuestion === question && i > 0) {
        setSelectedQuestion(performance[i - 1]);
      }
    });

    if (selectedQuestion === performance[0]) {
      data.KPIs.forEach((per, i) => {
        if (activeIndex.includes(per.kpiId) && i > 0) {
          const previousKpiId = data.KPIs[i - 1].kpiId;
          const previousKpiName=data.KPIs[i - 1].kpiName;
          setActiveIndex([previousKpiId]);
          handleTabChange(previousKpiId,previousKpiName);
        }
      });
    }
  };

  const isPreviousDisabled = () => {
    const currentKpiIndex = data?.KPIs?.findIndex((kpi) =>
      activeIndex.includes(kpi.kpiId)
    );
    const currentQuestionIndex = performance.findIndex(
      (question) => question === selectedQuestion
    );
    return currentKpiIndex === 0 && currentQuestionIndex === 0;
  };

  const handleNext = () => {
    const dataLength = performance.length;

    performance.forEach((question, i) => {
      if (selectedQuestion === question) {
        setSelectedQuestion(performance[i + 1]);
      }
    });
    if (performance[dataLength - 1] === selectedQuestion) {
      data.KPIs.forEach((per, i) => {
        if (activeIndex.includes(per.kpiId)) {
          setActiveIndex([data.KPIs[i + 1].kpiId]);
          handleTabChange(data.KPIs[i + 1].kpiId,data.KPIs[i + 1].kpiName);
        }
      });
    }
  };

  const isNextDisabled = () => {
    const currentKpiIndex = data?.KPIs?.findIndex((kpi) =>
      activeIndex.includes(kpi.kpiId)
    );
    const currentQuestionIndex = performance.findIndex(
      (question) => question === selectedQuestion
    );
    return (
      currentKpiIndex === data?.KPIs?.length - 1 &&
      currentQuestionIndex === performance.length - 1
    );
  };

  //#endregion

  //#region all Templates

  const changeResonFooter = () => {
    return (
      <div>
        <Button
          label="Submit"
          className="kpi-save-button"
          onClick={handleAdminReasonBtn}
          style={{ backgroundColor: ThemeColors?.primaryColor }}
        />
      </div>
    );
  };

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const valueTemplate = (value: number, total: number) => {
    return (
      <div style={{ color: 'black' }}>
        {/* {Math.floor((value / 100) * total)}/<b>{total}</b> */}
      </div>
    );
  };

  const handleSubmit = () => {
    confirmDialog({
      message: (
        <div style={{ textAlign: "center" }}>
          <p> Are you sure you want to save and submit this employee form?</p>
          <p>
            {" "}
            Once you submit the form again, you won't be able to make any
            further changes.
          </p>
        </div>
      ),
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      headerStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      style: {
        width: "350px",
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      contentStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      accept: () => accept(true),
      reject,
    });
  };

  //#endregion

  useEffect(() => {
    // Initialize button colors
    const initialColors: { [key: string]: string } = {};
    performance.forEach((question) => {
      question?.subKpiId && (initialColors[question?.subKpiId] = "white");
    });
    setButtonColors(initialColors);
    setSelectedQuestion(performance[0]);
    setButtonColors({ ...buttonColors, [performance[0]?.subKpiId]: "skyblue" });
  }, [performance, date]);

  useEffect(() => {
    setVisitedQuestions((preVisitedQuestion: any) => {
      return {
        ...preVisitedQuestion,
        [(selectedQuestion as GetAllSubKpiWithOptionsDto)?.subKpiId]: true,
      };
    });
    if (selectedQuestion) {
      // Update button colors when a question is selected
      const newColors = { ...buttonColors };
      performance.forEach((question: any) => {
        const answered =
          allAnswersByUser[question?.subKpiName] ||
          allAnswersByUser[question?.subKpiName] === 0
            ? true
            : false;
        if (selectedQuestion?.subKpiId === question?.subKpiId) {
          newColors[question?.subKpiId] = "skyblue";
          handleSkippedSubKpi(question?.subKpiId, false);
        } else if (answered) {
          newColors[question?.subKpiId] = "green";
          handleSkippedSubKpi(question?.subKpiId, false);
        } else if (visitedQuestions[question?.subKpiId]) {
          newColors[question?.subKpiId] = "red";
          handleSkippedSubKpi(question?.subKpiId, true);
        } else {
          newColors[question?.subKpiId] = "white";
          handleSkippedSubKpi(question?.subKpiId, true);
        }
      });
      setButtonColors(newColors);
    }
  }, [selectedQuestion, allAnswersByUser, date]);

  useEffect(() => {
    handleData();
    setIsOpenAdminReason(false);
  }, [date, disableButtons]);

  useEffect(() => {
    const handleByDefaultOpenAccordion = async () => {
      if (data && data?.KPIs?.length > 0) {
        // Set the first KPI's ID as active by default
        await getSubKpiPerformanceData(data?.KPIs[0]?.kpiId,data?.KPIs[0]?.kpiName);
        setActiveIndex([data?.KPIs[0]?.kpiId]);
      }
    };
    handleByDefaultOpenAccordion();
    setVisitedQuestions({});
  }, [date]);

  useEffect(() => {
    fetchLoggedInUserData();
    fetchRole();
  }, []);

  // useEffect(()=>{
  //   if (data && typeof data === 'object' && Object.keys(data).length === 0) {
  //     navigate('/performance');
  //   }
  // },[data])

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: ThemeColors?.primaryColor,
        }}
      />
      <Card
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <div className="main-performance-view">
          <div
            className="performance-view-header"
            style={{ backgroundColor: ThemeColors?.primaryColor }}
          >
            <h4>
              {getTheMonthName(date)} {reviewMode.toLowerCase()} form for{" "}
              {data?.EmployeeName} ({props?.employeeCode})
            </h4>
            <div className="date-input rating-date">
              <Button
                icon={<AiOutlineLeft />}
                onClick={handlePrevMonth}
                className="date-comp-prev-btn arr-btn-view"
                style={{ backgroundColor: "#E4F1FF", color: "black" }}
              />
              <DatePicker
                selected={date}
                value={date}
                customInput={<CustomInput />}
                onChange={handleDate}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
              />
              <Button
                icon={<AiOutlineRight />}
                onClick={handleNextMonth}
                className="date-comp-next-btn arr-btn-view"
                style={{ backgroundColor: "#E4F1FF", color: "black" }}
              />
            </div>
          </div>
        </div>
        {role?.toLowerCase() === "admin" && (
          <div className="average-rating-for-admin">
            <div
              className="score-box"
              style={{ backgroundColor: ThemeColors?.primaryColor }}
            >
              <h3 className="m-0 d-flex gap-3 align-items-center">
                <span>Score:</span>
                <span className="line"></span>
                <span>{score}%</span>
              </h3>
            </div>
          </div>
        )}
        <div
          className="all-performance-view row mt-0"
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        >
          {data ? (
            <>
              {data?.KPIs?.map((per, i) => (
                <div className="loop_accordian col-12 col-lg-6">
                  <Accordion
                    key={per.kpiId}
                    onTabChange={() => handleTabChange(per.kpiId,per.kpiName)}
                    activeIndex={activeIndex.includes(per.kpiId) ? [0] : []}
                    className="p-2 main-performance main-performance_review grid"
                    multiple
                    style={{ borderRadius: "15px" }}
                    expandIcon={
                      <AiFillRightCircle
                        style={{ marginRight: "30px" }}
                        size={22}
                      />
                    }
                    collapseIcon={
                      <AiFillDownCircle
                        style={{ marginRight: "10px" }}
                        size={22}
                      />
                    }
                  >
                    <AccordionTab
                      key={per.kpiId}
                      className="per-head"
                      header={
                        <div className="per-head-container">
                          <div className="per-head-all-elements px-5 py-2">
                            <div>{per?.kpiName?.toUpperCase()}</div>

                            <div className="per-sub-elements">
                              <p>
                                {per.target !== null &&
                                  `(Target: ${per.target})`}
                              </p>
                              {role?.toLowerCase() === "admin" && (
                                <p> (Weightage: {per.weightage})</p>
                              )}
                              <p className="accordionIndex">
                                {per.TotalSubKpiCount}
                              </p>
                            </div>
                          </div>
                          <ProgressBar className="w-12" value={((per?.CompletedSubPerformanceCount as number) / (per.TotalSubKpiCount as number)) * 100} displayValueTemplate={() => valueTemplate((((per?.CompletedSubPerformanceCount as number) / (per.TotalSubKpiCount as number)) * 100), (per.TotalSubKpiCount as number))} color="white" style={{ width: '28%' }} ></ProgressBar>
                        </div>
                      }
                      headerStyle={{
                        backgroundColor: ThemeColors?.primaryColor,
                        color: ThemeColors?.fontColor,
                      }}
                    >
                      <div className="content p-0">
                        {!performanceLoading ? (
                          <>
                            <div className="all-sub-kpi-details">
                              <div className="per-head-desc bg-primary-100 rounded-3 text-dark p-3 mb-3">
                                {per?.kpiInfo}
                              </div>
                              <div className="px-3 py-2">
                                <p
                                  className="sub-kpi-name"
                                  style={{ color: mode?.color }}
                                >
                                  {selectedQuestion?.subKpiName}
                                </p>
                                <p
                                  className="sub-kpi-desc"
                                  style={{ color: mode?.color }}
                                >
                                  {selectedQuestion?.subKpiDescription}
                                </p>
                                <div className="radio-btns">
                                  {selectedQuestion?.options?.sort((a,b)=>a.OptionId-b.OptionId).map(
                                    (
                                      option: OptionDetailsDto,
                                      optionIndex: number
                                    ) => (
                                      <div key={optionIndex}>
                                        <RadioButton
                                          inputId={`${selectedQuestion?.subKpiId}-${optionIndex}`}
                                          name={`question-${selectedQuestion?.subKpiId}`}
                                          value={option.Label}
                                          disabled={disableRadioButtons}
                                          onChange={() =>
                                            handleAnswerChange({
                                              Value: option.Value,
                                              Score: selectedQuestion.score,
                                              SubKpiId:
                                                selectedQuestion?.subKpiId,
                                              SubKpiName:
                                                selectedQuestion.subKpiName,
                                              OptionData: JSON.stringify(
                                                selectedQuestion.options
                                              ),
                                              SubmittedDate: ISTToDate(
                                                date
                                              ) as string,
                                              EmployeeCode: props?.employeeCode,
                                              KPIId: per.kpiId,
                                              allKpiIds: allKpiIds,
                                              KPIName:per.kpiName,
                                            
                                            })
                                          }
                                          checked={
                                            allAnswersByUser[
                                              selectedQuestion?.subKpiName
                                            ] == option.Value
                                          }
                                        />
                                        <label
                                          htmlFor={`${selectedQuestion?.subKpiId}-${optionIndex}`}
                                          className="ml-2 capitalize"
                                          style={{ color: mode?.color }}
                                        >
                                          {option.Label}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="all-buttons">
                              {performance.map((question, questionIndex) => (
                                <button
                                  key={questionIndex}
                                  onClick={() => {
                                    setSelectedQuestion(question);
                                  }}
                                  style={{
                                    backgroundColor:
                                      selectedQuestion == question
                                        ? "skyblue"
                                        : question.value
                                        ? "green"
                                        : buttonColors[question?.subKpiId],
                                    color: "white",
                                    height: "24px",
                                    width: "24px",
                                    margin: "0 5px",
                                    border: "2px solid #ced4da",
                                    borderRadius: "50%",
                                  }}
                                ></button>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div className="performanceLoader">Loading...</div>
                        )}
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              ))}
            </>
          ) : (
            <div>No result to show</div>
          )}
        </div>
        <div
          className="performance-view-buttons buttonWrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            label="Prev"
            onClick={handlePrevious}
            style={{
              backgroundColor: ThemeColors?.primaryColor,
              color: ThemeColors?.fontColor,
              border: "none",
            }}
            disabled={isPreviousDisabled()}
          />
          <Button
            label="Next"
            onClick={handleNext}
            style={{
              backgroundColor: ThemeColors?.primaryColor,
              color: ThemeColors?.fontColor,
              border: "none",
            }}
            disabled={isNextDisabled()}
          />
          <Button
            label="Submit"
            onClick={handleSubmit}
            disabled={disableButtons}
            style={{
              backgroundColor: ThemeColors?.primaryColor,
              color: ThemeColors?.fontColor,
              border: "none",
            }}
          />
          <Button
            label="Cancel"
            onClick={handleRoute}
            style={{
              backgroundColor: ThemeColors?.primaryColor,
              color: ThemeColors?.fontColor,
              border: "none",
            }}
          />
        </div>

        <Dialog
          header={<p>Reason For Change</p>}
          className="dialog reason-change-dialog"
          footer={changeResonFooter}
          visible={isVisibleChangeReson}
          style={{
            width: "50vw",
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
          contentStyle={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
          headerStyle={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
          onHide={() => setIsVisibleChangeReson(false)}
        >
          <InputTextarea
            value={changeReasonVal}
            onChange={(e) => setChangeReasonVal(e.target.value)}
            className="reason-input"
            autoResize={true}
          />
        </Dialog>
      </Card>
    </div>
  );
};
