import React, { useEffect, useRef, useState } from "react";
import "../../css/components/addGoal.css";
import { useDispatch, useSelector } from "react-redux";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  createGoalWithSteps,
  getAllGoals,
  getGoalByGoalName,
} from "../../service/goals/goalsService";
import { CreateGoalsWithStepsDto } from "../../dtos/goals/CreateGoalsWithStepsDto";
import { GoalStepsDto } from "../../dtos/goals/GoalsStepsDto";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { GoalDto } from "../../dtos/goals/GoalsDto";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { getAllSkills } from "../../service/skill/skillServices";
import { SkillDto } from "../../dtos/skill/SkillsDto";
import { SkillInfoDto } from "../../dtos/skills/SkillInfoDto";

interface AddGoalsProps {
  showAddNewGoal: boolean;
  setShowAddNewGoal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedGoal: React.Dispatch<React.SetStateAction<GoalDto | null>>;
  getGoals?: () => void;
  allDataFunc?: () => void;
}

const AddGoal: React.FC<AddGoalsProps> = ({
  showAddNewGoal,
  setShowAddNewGoal,
  setSelectedGoal,
  getGoals,
  allDataFunc,
}) => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const [goalErrors, setGoalErrors] = useState<{
    Name: boolean;
    Duration: boolean;
    Description: boolean;
    Skills:boolean;
    goalSteps: { [x: number]: { Name?: boolean; Score?: boolean } };
  }>({ Name: false, Duration: false, Description: false,Skills:false, goalSteps: {} });

  const [goal, setGoal] = useState({
    Name: "",
    Duration: 0,
    Description: "",
  });

  const [goalSteps, setGoalSteps] = useState<
    {
      id: number;
      Name: string;
      Description: string;
      Score: number;
      OrderNumber: number;
      isNew?: boolean;
    }[]
  >([]);

const [selectedSkills, setSelectedSkills] = useState<SkillDto[]|[]>([]);
const [allSkills,setAllSkills] = useState<SkillInfoDto[]|[]>([])

  //#endregion

  //#region all Functions

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const dataToSendToBackend = () => {
    const nameSet = new Set<string>();
    const duplicates: string[] = [];
    const updatedGoalStep: GoalStepsDto[] = goalSteps.map((goalstep: any) => {
      if (nameSet.has(goalstep.Name)) {
        duplicates.push(goalstep.Name);
      } else {
        nameSet.add(goalstep.Name);
      }

      const { id, isNew, Score, Name, Description, ...rest } = goalstep;
      const trimmedName = Name ? Name.trim() : "";
      const trimmedDesc = Description ? Description.trim() : "";

      return {
        ...rest,
        Name: trimmedName,
        Description: trimmedDesc,
        Score: parseInt(Score),
        
      };
    });
    if (duplicates.length > 0) {
      showToast("error", "Steps with same name can't be created", "Error");
      return null;
    }

    return {
      ...goal,
      Name: goal.Name.trim(),
      Description: goal.Description.trim(),
      Duration: parseInt(goal.Duration as any),
      GoalSteps: updatedGoalStep,
      Skills:selectedSkills
    };
  };
  const handleErrors = () => {
    let errors = { ...goalErrors };
    if (!goal.Name.trim()) {
      errors.Name = true;
    }
    if (
      goal.Duration === undefined ||
      goal.Duration === null ||
      goal.Duration === 0
    ) {
      errors.Duration = true;
    }

    goalSteps.map((goalstep: any, i) => {
      if (!goalstep.Name.trim()) {
        errors.goalSteps[goalstep.id] = {
          ...errors.goalSteps[goalstep.id],
          Name: true,
        };
      }
      if (
        goalstep.Score === undefined ||
        goalstep.Score === null ||
        goalstep.Score === 0
      ) {
        errors.goalSteps[goalstep.id] = {
          ...errors.goalSteps[goalstep.id],
          Score: true,
        };
      }
    });
    if(selectedSkills.length===0){
      errors.Skills = true;
    }
    setGoalErrors(errors);
  };
  const validateGoalData = () => {
    handleErrors();
    // Check if goal Name is empty or Duration is zero
    if (!goal.Name.trim() || !goal.Duration) {
      return false;
    }

    if (!goal.Duration) {
      return false;
    }

    for (let goalStep of goalSteps) {
      if (!goalStep.Name.trim() || !goalStep.Score) {
        return false;
      }
    }
    if(selectedSkills.length===0){
        return false;
    }

    if (goalSteps.length < 2) {
      return null;
    }

    return true;
  };

  const createGoal = async () => {
    if (validateGoalData()) {
      const ifGoalExist = await getGoalByGoalName(dispatch, {
        GoalName: goal.Name.trim(),
      });
      if (ifGoalExist) {
        return showToast(
          "error",
          "A goal with this name already exist",
          "Error"
        );
      } else {
        const data: CreateGoalsWithStepsDto | null = dataToSendToBackend();
        if (data === null) {
          // Stop further processing if dataToSendToBackend returned null
          return;
        }
        const res = await createGoalWithSteps(dispatch, data);
        showToast("success", "Goal created successfully", "Success");
        setShowAddNewGoal(false);
        if (allDataFunc) {
          allDataFunc();
        }
        if (getGoals) {
          getGoals();
        }

        resetAddGoalDialog();
      }
    } else {
      if (validateGoalData() === null) {
        showToast(
          "error",
          "Create atleast two goal steps to create goal",
          "Error"
        );
      } else if (validateGoalData() === false && goalSteps.length < 2) {
        showToast(
          "error",
          "Please fill all required fields and create atleast two goal steps to create goal",
          "Error"
        );
      } else {
        showToast(
          "error",
          "Please fill all required fields to create goal",
          "Error"
        );
      }
    }
  };

  const onGoalChange = (name: string, value: string | number) => {
    setGoalErrors({ ...goalErrors, [name]: false });
    setGoal((prevGoal) => ({
      ...prevGoal,
      [name]: value,
    }));
  };

  const updateGoalStep = (id: number, name: string, value: string | number) => {
    let errors = goalErrors;
    errors.goalSteps[id] = { ...errors.goalSteps[id], [name]: false };

    setGoalErrors(errors);

    setGoalSteps((prevSteps) =>
      prevSteps.map((step) =>
        step.id === id ? { ...step, [name]: value } : step
      )
    );
  };

  const addGoalStep = () => {
    setGoalSteps((prevSteps) => [
      ...prevSteps,
      {
        id: Date.now(),
        Name: "",
        Description: "",
        Score: 0,
        isNew: true,
        OrderNumber: prevSteps.length === 0 ? 0 : prevSteps.length - 1 + 1,
      },
    ]);
  };

  const removeGoalStep = (id: number) => {
    setGoalSteps((prevSteps) => prevSteps.filter((step) => step.id !== id));
  };

  const resetAddGoalDialog = () => {
    setGoal({
      Name: "",
      Duration: 0,
      Description: "",
    });
    setGoalSteps([]);
    setGoalErrors({
      Name: false,
      Duration: false,
      Description: false,
      Skills:false,
      goalSteps: {},
    });
    setSelectedSkills([])
  };

  const getSkills = async ()=>{
    const skills = await getAllSkills(dispatch)
    setAllSkills(skills)

  }

  //#endregion

  //#region all Templates
  const headerTemplate = () => {
    return (
      <div className="add-goal-header">
        <h1 className="add-goal-heading">{localization?.AddNewGoal || "Add New Goal"}</h1>
      </div>
    );
  };

  const addGoalFooterTemplate = () => {
    return (
      <div className="form-actions">
        <Button type="submit" onClick={createGoal}>
          {localization?.SaveGoal || "Save Goal"}
        </Button>
        <Button
          type="button"
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            border: `1px solid ${modeName === "light" ? "#970FFF" : "white"}`,
            color: `${modeName === "light" ? "#970FFF" : "white"}`,
          }}
          onClick={() => {
            setShowAddNewGoal(false);
            resetAddGoalDialog();
          }}
        >
           {localization?.Cancel || "Cancel"}
        </Button>
      </div>
    );
  };

  //#endregion

  useEffect(()=>{
    if (showAddNewGoal) {
      getSkills()
    }
  },[showAddNewGoal])

  useEffect(()=>{
    console.log(selectedSkills)
  },[selectedSkills])

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="add_New_Goal"
        header={headerTemplate}
        footer={addGoalFooterTemplate}
        visible={showAddNewGoal}
        style={{ width: "70vw" }}
        onHide={() => {
          if (!showAddNewGoal) return;
          setShowAddNewGoal(false);
          setSelectedGoal(null);
          resetAddGoalDialog();
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        maskStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        draggable={false}
      >
        <div>
          <div
            className="goal-form pt-0"
            style={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
          >
            <div className="form-group">
              <label htmlFor="goal-name" style={{ color: mode?.color }}>
              {localization?.GoalName || "Goal Name"}*
              </label>
              <div className="goal-name-input-box-container">
                <InputText
                  id="goal-name"
                  name="Name"
                  value={goal.Name}
                  onChange={(e) => onGoalChange(e.target.name, e.target.value)}
                />

                {goalErrors.Name && (
                  <span className="err-msg">*Please enter goal name</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="duration" style={{ color: mode?.color }}>
              {localization?.Duration || "Duration"} ({localization?.Days || "Days"})*
              </label>
              <div 
              className="duration-input-box-container"
              >
                <InputNumber
                  inputId="integeronly"
                  value={goal.Duration}
                  name="Duration"
                  onChange={(e) => {
                    onGoalChange("Duration", e.value!);
                  }}
                  onInput={(e: any) => {
                    onGoalChange("Duration", e.target.value);
                  }}
                  min={0}
                />
                {goalErrors.Duration && (
                  <span className="err-msg">*Please enter duration</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="duration" style={{ color: mode?.color }}>
              {localization?.Skills || "Skills"}*
              </label>
              <div className="duration-input-box-container">
              <MultiSelect style={{color:'black'}} value={selectedSkills} onChange={(e) => {setSelectedSkills(e.value);setGoalErrors({ ...goalErrors, Skills: false });}} options={allSkills} optionLabel="Name" 
                placeholder="Select Skills" maxSelectedLabels={3} className="w-full md:w-20rem" />
                 {goalErrors.Skills && (
                  <span className="err-msg">*Please enter skill</span>
                )}
              </div>

            </div>
            <div className="form-group">
              <label htmlFor="goal-description" style={{ color: mode?.color }}>
              {localization?.GoalDescription || "Goal Description"}
              </label>
              <InputTextarea
                id="goal-description"
                name="Description"
                value={goal.Description}
                onChange={(e) => onGoalChange(e.target.name, e.target.value)}
                rows={5}
                cols={30}
                className="custom-textarea"
              />
            </div>
            <div className="add_goals_table_res">
              <table>
                <thead>
                  <tr>
                    <th>{localization?.GoalSteps || "Goal Steps"}*</th>
                    <th>{localization?.GoalDescription || "Goal Description"}</th>
                    <th>{localization?.Score || "Score"}*</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {goalSteps.map((goalStep) => {
                    return (
                      <tr>
                        <td>
                          <InputText
                            className="goal-name w-100"
                            name="goalName"
                            value={goalStep.Name}
                            onChange={(e) =>
                              updateGoalStep(
                                goalStep.id,
                                "Name",
                                e.target.value
                              )
                            }
                            style={{
                              border: `${
                                goalErrors.goalSteps[goalStep.id]?.Name
                                  ? "1px solid red"
                                  : goalStep.isNew
                                  ? "1px solid gray"
                                  : "none"
                              }`,
                            }}
                          />
                        </td>
                        <td>
                          <InputTextarea
                            className="goal-description custom-textarea w-100"
                            value={goalStep.Description}
                            onChange={(e) =>
                              updateGoalStep(
                                goalStep.id,
                                "Description",
                                e.target.value
                              )
                            }
                            rows={3}
                            cols={30}
                            style={{
                              border: goalStep.isNew
                                ? "1px solid gray"
                                : "none",
                            }}
                          />
                        </td>
                        <td>
                          <InputNumber
                            inputId="integeronly"
                            value={goalStep.Score}
                            name="Score"
                            onChange={(e) => {
                              updateGoalStep(goalStep.id, "Score", e.value!);
                            }}
                            onInput={(e: any) => {
                              updateGoalStep(
                                goalStep.id,
                                "Score",
                                e.target.value
                              );
                            }}
                            min={0}
                            inputClassName="score-input-box"
                            inputStyle={{
                              border: `${
                                goalErrors.goalSteps[goalStep.id]?.Score
                                  ? "1px solid red"
                                  : goalStep.isNew
                                  ? "1px solid gray"
                                  : "none"
                              }`,
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            className="round-button"
                            onClick={() => removeGoalStep(goalStep.id)}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <Button
                      className="add-row-button round-button"
                      onClick={addGoalStep}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddGoal;
