import { Card } from "primereact/card";
import "../../css/components/dashboard.css";
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  getRatingsByKpiOfLoggedInUser,
  getTopPerformedEmployee,
} from "../../service/performance/PerformanceService";
import { useDispatch, useSelector } from "react-redux";
import { Toolbar } from "primereact/toolbar";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Calendar } from "primereact/calendar";
import { DashboardInputDto } from "../../dtos/dashboard/DashboardInputDto";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import {
  getTopBilledEmployee,
  getTopTrackingProjects,
  getTopBillingProjects,
  getAllBilledEmployee,
} from "../../service/employeeBilling/EmployeeBillingService";
import { TopPerformingEmp } from "../../dtos/dashboard/TopPerformingEmp";
import { TopBillingEmpDto } from "../../dtos/dashboard/TopBillingEmpDto";
import { ShowMinToHourAndMin } from "../../shared/functions/ShowMinToHourAndMin";
import { TopBillingProjChart } from "../charts/TopBillingProjChart";
import { LoggedInEmpPerformanceChart } from "../charts/LoggedInEmpPerformanceChart";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { TotalRatingsByKpiInputDto } from "../../dtos/performance/TotalRatingsByKpiInputDto";
import { TopTrackingProjChart } from "../charts/TopTrackingProjChart";
import {
  getSubordinatesNameIDCode,
} from "../../service/employee/employeeService";
import { isNotUpcomingDate } from "../../shared/functions/IsNotUpcomingDateFunc";
import DatePicker from "react-datepicker";
import { GetPrevFirstAndLastDayOfWeek } from "../../shared/functions/GetPrevFirstAndLastDayOfWeek";
import { GetNextFirstAndLastDayOfWeek } from "../../shared/functions/GetNextFirstAndLastDayOfWeek";
import { LoggedInEmpPerformanceChartForTwo } from "../charts/LoggedInEmpPerformanceChartForTwo";
import { LoggedInPerformanceData } from "../../dtos/performance/LoggedInPerformanceData";
import { EmployeePerformanceChart } from "../charts/EmployeePerformanceChart";
import { Toast } from "primereact/toast";
import { TopPerformingBillGroupProjDto } from "../../dtos/dashboard/TopPerformingBillGroupProjDto";
import { ShowSecToHourAndMin } from "../../shared/functions/ShowSecToHourAndMin";
import { TopPerformingTrackGroupProjDto } from "../../dtos/dashboard/TopPerformingTrackGroupProjDto";
import { getProfilePicFromTracker } from "../../shared/functions/GetProfilePicFromTracker";
import { Dialog } from "primereact/dialog";
import { AllBilledEmpDto } from "../../dtos/dashboard/AllBilledEmpDto";
import AllBilledEmpChart from "../charts/AllBilledEmpChart";
import { AllBilledEmpResultDto } from "../../dtos/dashboard/AllBilledEmpResultDto";
import { GetPreviousMonth } from "../../shared/functions/GetPreviousMonth";
import { getUserRole } from "../../service/user/userService";
import { getUniqueSkillWithEmpCount } from "../../service/userSkill/userSkillServices";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";

export const Dashboard = () => {
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [date, setDate] = useState<any>(GetPreviousMonth(new Date()));
  const [dateType, setDateType] = useState<string>("month");
  const [calender, setCalender] = useState(
    <Calendar
      value={date}
      onChange={(e:any) => setDate(e.value)}
      style={{ backgroundColor: "red" }}
    />
  );
  const buttons = [
    { name: "Week", value: "week" },
    { name: "Month", value: "month" },
    { name: "Year", value: "year" },
  ];
  const [topPerformingEmp, setTopPerformingEmp] = useState<TopPerformingEmp[]>(
    []
  );
  const [topBillingEmp, setTopBillingEmp] = useState<TopBillingEmpDto[]>([]);
  const [allBilledEmployee, setAllBilledEmployee] = useState<
    AllBilledEmpResultDto[]
  >([]);
  const [allBillEmpTotalCount, setAllBillEmpTotalCount] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(5);
  const [page, setPage] = useState<number>(0);

  const [loggedInEmpPerformanceData, setloggedInEmpPerformanceData] = useState<
    LoggedInPerformanceData[]
  >([]);
  const [topTrackingProjData, setTopTrackingProjData] = useState<
    TopPerformingTrackGroupProjDto[]
  >([]);
  const [topBillingProjData, setTopBillingProjData] = useState<
    TopPerformingBillGroupProjDto[]
  >([]);
  const [showOnlyUserChart, setShowOnlyUserChart] = useState<boolean>(true);
  const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [skillsWithEmpCount, setSkillsWithEmpCount] = useState<
    string | { SkillName: string; employeeCount: number }[] | []
  >([]);
  // for week
  const firstDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - new Date().getDay() - 6
  );
  const lastDay = new Date(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay.getDate() - firstDay.getDay() + 7
  );

  const [weekDate, setWeekDate] = useState<any>([firstDay, lastDay]);
  const [monthDate, setMonthDate] = useState<any>(new Date());
  //for year
  const [yearDate, setYearDate] = useState<any>(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const toast = useRef<any>(null);

  //#region all Functions

  //after clicking the week button dates will set to week

  const handleWeek = () => {
    if (weekDate.length > 0) {
      const dt = [weekDate[0], weekDate[1]];
      setDate(dt);
    } else {
      const today = new Date(weekDate);
      const first = new Date(today);
      first.setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      );
      const last = new Date(today);
      last.setDate(first.getDate() + 6);
      const dateRange = [first, last];
      setDate(dateRange);
    }
  };

  //after clicking the year button dates will set to year
  const handleYear = () => {
    setDate(yearDate);
  };
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page);
  };

  const resetAllBilledEmpPaginator = () => {
    setFirst(0);
    setRows(5);
    setPage(0);
  };

  //after clicking the month button dates will set to month
  const handleMonth = () => {
    setDate(monthDate);
  };

  //For handling date select component buttons
  const handleDate = (e: any) => {
    let dateTypeForPicker;
    if (e.value === null) {
      dateTypeForPicker = dateType;
      setDateType((prev: any) => {
        return prev;
      });
    } else {
      setDateType(e.value);
      dateTypeForPicker = e.value;
    }

    if (e.value === "week") {
      handleWeek();
    } else if (e.value === "month") {
      handleMonth();
    } else if (e.value === "year") {
      handleYear();
    }
  };

  //For previous date button
  const handlePrevDate = (type: string, date: any) => {
    if (type === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay.getMonth() - 1);
      setDate(firstDay);
      setMonthDate(firstDay);
    } else if (type === "week") {
      const daysOfWk = GetPrevFirstAndLastDayOfWeek(date);
      const dateRange = [];
      dateRange.push(daysOfWk.first, daysOfWk.last);
      setDate(dateRange);
      setWeekDate(dateRange);
    } else if (type === "year") {
      const prevY = new Date(date.setFullYear(date.getFullYear() - 1));
      setDate(prevY);
      setYearDate(prevY);
    }
  };

  //For next date button
  const handleNextDate = (type: string, date: any) => {
    if (type === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay?.getMonth() + 1);

      //if the date is upcoming date then set it current date
      if (!isNotUpcomingDate(firstDay.toString())) {
        setDate((prev: any) => {
          const newDate = prev;
          return newDate;
        });
        toast.current.show({
          severity: "warn",
          summary: "Sorry!",
          detail: "You can't go to the current and upcoming months!",
          life: 3000,
        });
        return;
      }
      setDate(firstDay);
      setMonthDate(firstDay);
    } else if (type === "week" || type === "dateRange") {
      const daysOfWk = GetNextFirstAndLastDayOfWeek(date);
      const dateRange = [];
      dateRange.push(daysOfWk.first, daysOfWk.last);
      setDate(dateRange);
      setWeekDate(dateRange);
    } else if (type === "year") {
      const nextY = new Date(date.setFullYear(date.getFullYear() + 1));
      setDate(nextY);
      setYearDate(nextY);
    }
  };

  //This function will give first and last day of month from a selected single date
  function getFirstAndLastDatesOfMonth(inputDate: Date) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 1);
    return {
      firstDay,
      lastDay,
    };
  }
  const getFirstAndLastDayOfWeek = (date: any) => {
    const today = new Date(date[0]); // Create a clone of the input date

    // Set the first day of the week (Monday)
    const firstDay = new Date(today);
    firstDay.setDate(
      today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
    );

    // Set the last day of the week (Sunday)
    const lastDay = new Date(firstDay);
    lastDay.setDate(firstDay.getDate() + 6);

    const weekDates = [firstDay, lastDay];
    setDate(weekDates); // Assuming setDate is a function you want to use to set the week dates
    setWeekDate(weekDates);
  };

  //This function will decide which calender will show according to the dateType
  const handleCalender = () => {
    if (dateType === "month") {
      setCalender(
        <DatePicker
          selected={date}
          customInput={<CustomInput />}
          value={date}
          onChange={(e: any) => setDate(e)}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
        />
      );
      setMonthDate(date);
    } else if (dateType === "week") {
      setCalender(
        <DatePicker
          selectsRange={date}
          customInput={<CustomInput />}
          startDate={date[0]}
          endDate={date[1]}
          onChange={(e: any) => getFirstAndLastDayOfWeek(e)}
          dateFormatCalendar="MMMM yyyy"
        />
      );
    } else if (dateType === "year") {
      setCalender(
        <DatePicker
          selected={date}
          value={date}
          customInput={<CustomInput />}
          onChange={(e) => setDate(e)}
          dateFormat="yyyy"
          showYearPicker
        />
      );
      setYearDate(date);
    }
  };

  const createStartAndEndDate = (input: Date[]) => {
    let dates: DashboardInputDto = {
      startDate: "",
      endDate: "",
    };
    if (date.length > 0) {
      const allStrDates = ISTToDate(input);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    }
    //If there is only a single date and Month button is selected
    else if (date.length === undefined && dateType === "month") {
      const firstAndLastDate = getFirstAndLastDatesOfMonth(date);
      const dt = [firstAndLastDate.firstDay, firstAndLastDate.lastDay];
      const allStrDates = ISTToDate(dt);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    } else if (dateType === "year") {
      const currentYear = date.getFullYear();
      const allStrDates = ISTToDate([
        new Date(currentYear, 0, 1),
        new Date(currentYear, 11, 31),
      ]);
      dates = {
        startDate: allStrDates[0],
        endDate: allStrDates[1],
      };
    }
    return dates;
  };

  const handleTopPerformingEmp = async () => {
    const inputForPresentDate = dateType === "week" ? date[1] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate && dateType !== "week") {
      let input;
      input = createStartAndEndDate(date);
      const emp = await getTopPerformedEmployee(dispatch, input);
      if (emp) {
        setTopPerformingEmp(emp);
      }
    } else {
      setTopPerformingEmp([]);
    }
  };

  const handleTopBillingEmp = async () => {
    const inputForPresentDate = dateType === "week" ? date[1] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate && dateType !== "week") {
      const input = createStartAndEndDate(date);
      const emp = await getTopBilledEmployee(dispatch, input);
      if (!emp) {
        setTopBillingEmp([]);
        return;
      }
      const promises = emp.map(async (e: any) => {
        const input = {
          EmployeeID: e.EmployeeID,
          EmployeeCode: e.EmployeeCode,
        };
        const image = await getProfilePicFromTracker(dispatch, input);
        e.ProfilePicture = image;
        return e;
      });

      // Wait for all the Promises to resolve
      await Promise.all(promises);
      setTopBillingEmp(emp);
    }
  };


  const handleAllBilledEmployee = async () => {
    const inputForPresentDate = dateType === "week" ? date[1] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (isPresentDate && dateType !== "week") {
      const input = createStartAndEndDate(date);
      const inputWithLimitAndPage = { ...input, limit: rows, page: page };
      const emp: AllBilledEmpDto = await getAllBilledEmployee(
        dispatch,
        inputWithLimitAndPage
      );
      if (!emp) {
        setAllBilledEmployee([]);
        setAllBillEmpTotalCount(0);
        return;
      }
      setAllBilledEmployee(emp.result);
      setAllBillEmpTotalCount(emp.totalCount);
    }
  };

  const handleTopPerformingProj = async () => {
    let dateTypeForTracker = 0;
    switch (dateType) {
      case "week":
        dateTypeForTracker = 1;
        break;
      case "month":
        dateTypeForTracker = 2;
        break;
      case "year":
        dateTypeForTracker = 3;
        break;
    }
    const inputForPresentDate = dateType === "week" ? date[0] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);
    if (!isPresentDate) {
      setTopBillingProjData([]);
      setTopTrackingProjData([]);
      return;
    }

    const dates = createStartAndEndDate(date);
    const input = {
      dateType: dateTypeForTracker,
      startDate: dates.startDate,
      endDate: dates.endDate,
    };

    const topBillingProj: TopPerformingBillGroupProjDto[] =
      await getTopBillingProjects(dispatch, input);
    const topTrackingProj: TopPerformingTrackGroupProjDto[] =
      await getTopTrackingProjects(dispatch, input);

    if (topTrackingProj?.length > 0) {
      setTopTrackingProjData(topTrackingProj);
    } else {
      setTopTrackingProjData([]);
    }

    if (topBillingProj?.length > 0) {
      setTopBillingProjData(topBillingProj);
    } else {
      setTopBillingProjData([]);
    }
  };

  const handleloggedInEmpPerformanceChart = async () => {
    const inputForPresentDate = dateType === "week" ? date[1] : date;
    const isPresentDate = isNotUpcomingDate(inputForPresentDate, dateType);

    if (isPresentDate && dateType !== "week") {
      const loggedInUser = await LoggedInUserData(dispatch);
      let dates;
      if (dateType === "week") {
        const monthDates = getFirstAndLastDatesOfMonth(date[0]);
        const dt = [monthDates.firstDay, monthDates.lastDay];
        dates = createStartAndEndDate(dt);
      } else {
        dates = createStartAndEndDate(date);
      }
      const input: TotalRatingsByKpiInputDto = {
        empCode: loggedInUser?.EmployeeCode,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
      };

      if (date) {
        const res: LoggedInPerformanceData[] =
          await getRatingsByKpiOfLoggedInUser(dispatch, input);
        if (res) {
          if (Object?.keys(res)?.length > 0) {
            setloggedInEmpPerformanceData(res);
          } else {
            setloggedInEmpPerformanceData([]);
          }
        }
      }
    }
  };

  const getAllSubordinates = async () => {
    const res = await getSubordinatesNameIDCode(dispatch);
    if (res?.length <= 1) {
      setShowOnlyUserChart(false);
    }
  };
  const getSkillsWithEmpCount = async () => {
    const res = await getUniqueSkillWithEmpCount(dispatch);
    setSkillsWithEmpCount(res);
  };
  const handleAdminChart = async () => {
    const role = await getUserRole(dispatch);
    if (role === "admin") {
      setIsAdmin(true);
    }
  };

  const viewAllBilledEmployee = () => {
    setVisible(true);
    handleAllBilledEmployee();
  };

  

  //#endregion

  //#region all Templates
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const endToolbar = () => {
    return (
      <div className="report-start-tool">
        <SelectButton
          value={dateType}
          onChange={(e) => handleDate(e)}
          optionLabel="name"
          options={buttons}
          disabled={disableSelectBtn}
        />

        {/* visible input calender */}

        <div className="date-input">
          <Button
            icon={<AiOutlineLeft />}
            onClick={() => handlePrevDate(dateType, date)}
            className="date-comp-prev-btn"
            disabled={disableSelectBtn}
          />
          {calender}

          <Button
            icon={<AiOutlineRight />}
            onClick={() => handleNextDate(dateType, date)}
            className="date-comp-next-btn"
            disabled={disableSelectBtn}
          />
        </div>
      </div>
    );
  };
  //#endregion

  //#region all UseEffects

  useEffect(() => {
    handleAllBilledEmployee();
  }, [first, rows]);



  const handleAllFunc = async () => {
    setDisableSelectBtn(true);
    await Promise.all([
      handleloggedInEmpPerformanceChart(),
      handleTopBillingEmp(),
      handleTopPerformingEmp(),
      handleTopPerformingProj(),
    ]);
    setDisableSelectBtn(false);
  };

  useEffect(() => {
    handleCalender();
    handleAllFunc();
    handleAdminChart();
  }, [
    dateType,
    date,
    mode?.color,
    showOnlyUserChart,
    window.location.pathname,
  ]);

  useEffect(() => {
    getAllSubordinates();
  }, []);
  

  useEffect(() => {
    getSkillsWithEmpCount();
  }, []);

  //#endregion
  return (
    <div>
      <Toast ref={toast} />
      <Toolbar
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="dashboard_sec mb-3"
      />
      <div className="main-dashboard row">
        {/* TOP BILLING EMPLOYEE ******************/}
        {dateType !== "week" && (
          <div className="col-lg-4 mb-3 pr-1">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-billing-emp"
            >
              <div
                className="p-card-title dashboard-card-title"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h3 className="dashboard-card-head">
                  {localization?.TopBillingEmployee || "Top Billing Employee "}
                </h3>
                <span
                  className="viewAllBtn"
                  style={{
                    color: mode?.name === "light" ? "blue" : "white",
                  }}
                  onClick={viewAllBilledEmployee}
                >
                  View All
                </span>
              </div>
              <Dialog
                header="All Billed Employees"
                visible={visible}
                // style={{ width: "50vw" }}
                style={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                  width: "50vw",
                }}
                contentStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
                headerStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
                onHide={() => {
                  setVisible(false);
                  resetAllBilledEmpPaginator();
                }}
              >
                <p className="m-0">
                  {allBilledEmployee.length > 0 ? (
                    <div>
                      <AllBilledEmpChart data={allBilledEmployee} mode={mode} />
                      <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={allBillEmpTotalCount}
                        rowsPerPageOptions={[5, 10, 15]}
                        onPageChange={onPageChange}
                        style={{
                          color: mode?.color,
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ color: mode?.color }} className="no-data">
                      No data to show
                    </div>
                  )}
                </p>
              </Dialog>
              <div className="dashbord-card-cont">
                {topBillingEmp.length > 0 ? (
                  <div>
                    {topBillingEmp?.map((emp) => (
                      <div
                        key={emp?.EmployeeID}
                        className="top-performing-emp-box"
                      >
                        <div className="employees-picture">
                          <img
                            src={
                              emp?.ProfilePicture ||
                              "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                            }
                            alt="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                            onError={(event: any) =>
                              (event.target.src =
                                "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg")
                            }
                          />
                        </div>
                        <div className="dashboard-emp">
                          <p>
                            <span className="head-text">Name:</span>{" "}
                            {emp?.EmployeeName}
                          </p>
                          <p>
                            <span className="head-text">Code: </span>{" "}
                            {emp?.EmployeeCode}
                          </p>
                          <p>
                            <span className="head-text">Billing Time: </span>{" "}
                            {ShowMinToHourAndMin("", emp?.BillingTimeInMinute)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}
        {/* TOP SKILLED EMPLOYEE ******************/}
        {/* {dateType !== "week" && (
          <div className="col-lg-4 mb-3 pr-1">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-billing-emp"
            >
              <div
                className="p-card-title dashboard-card-title"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h3 className="dashboard-card-head">
                  {localization?.TopBillingEmployee || "Top Skilled Employee "}
                </h3>
                <span
                  className="viewAllBtn"
                  style={{
                    color: mode?.name === "light" ? "blue" : "white"
                  }}
                  onClick={viewAllSkilledEmployee}
                >
                  View All
                </span>
              </div>
              <Dialog
                header="All Skilled Employees"
                visible={showSkilledEmpDialog}
                // style={{ width: "50vw" }}
                style={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                  width: "50vw"
                }}
                contentStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
                headerStyle={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}
                onHide={() => {setShowSkilledEmpDialog(false);resetSkillDialogPaginator()}}
              >
                <p className="m-0">
                  {topSkilledEmp.length > 0 ? (
                    <div>
                      
                      <AllSkilledEmpChart data={topSkilledEmp} mode={mode} />
                      <Paginator
                        first={skillFirst}
                        rows={skillRows}
                        totalRecords={topSkilledEmpTotalCount}
                        rowsPerPageOptions={[5, 10, 15]}
                        onPageChange={onSkillDialogPageChange}
                        style={{
                          color: mode?.color,
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ color: mode?.color }} className="no-data">
                      No data to show
                    </div>
                  )}
                </p>
              </Dialog>
              <div className="dashbord-card-cont">
                {topSkilledEmp.length > 0 ? (
                  <div>
                    {allSkilledEmp?.slice(0,5).map((emp:any) => (
                      <div
                        key={emp?.employeeCode}
                        className="top-performing-emp-box"
                      >
                        <div className="employees-picture">
                          <img
                            src={
                              emp?.ProfilePicture ||
                              "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                            }
                            alt="https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                            onError={(event: any) =>
                            (event.target.src =
                              "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg")
                            }
                          />
                        </div>
                        <div className="dashboard-emp">
                          <p>
                            <span className="head-text">Name:</span>{" "}
                            {emp?.fullName}
                          </p>
                          <p>
                            <span className="head-text">Code: </span>{" "}
                            {emp?.employeeCode}
                          </p>
                          <p>
                            <span className="head-text">Skill Count: </span>{" "}
                            { emp?.skills.length}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
              </div>
            </Card>
          </div>
        )} */}
        {/*******************/}

        {!isAdmin && dateType !== "week" && (
          <div className={isAdmin ? "col-md-12 mb-3" : "col-md-8 mb-3"}>
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="user-performance-chart"
            >
              <div className="p-card-title dashboard-card-title">
                <h3 className="dashboard-card-head">
                  {localization?.YourPerformanceChart ||
                    "Your Performance Chart"}
                </h3>
              </div>
              <div className="dashbord-card-cont">
                {loggedInEmpPerformanceData?.length <= 2 &&
                loggedInEmpPerformanceData?.length > 0 ? (
                  <LoggedInEmpPerformanceChartForTwo
                    Data={loggedInEmpPerformanceData}
                  />
                ) : loggedInEmpPerformanceData?.length > 2 ? (
                  <LoggedInEmpPerformanceChart
                    Data={loggedInEmpPerformanceData}
                  />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}

        {/* Employee performance chart ****************/}
        {dateType !== "week" && (
          <div className={isAdmin ? "col-lg-8 mb-3" : "col-md-12 mb-3"}>
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-billing-emp"
            >
              <div className="p-card-title dashboard-card-title">
                <h3 className="dashboard-card-head">
                  {localization?.EmployeePerformance || "Employee Performance"}
                </h3>
              </div>

              <div className="dashbord-card-cont">
                {topPerformingEmp.length > 0 ? (
                  <EmployeePerformanceChart Data={topPerformingEmp} />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}
        {/* Skills ******************/}
        <Card className="col-md-12 mb-3 p-4 d-flex " 
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        >
        <h3 className="dashboard-card-head mb-2">
                  {localization?.EmployeeSkills || "Employee Skills"}
                </h3>
          <div className="skill-badge-container">
          {typeof skillsWithEmpCount !== "string"
            ? skillsWithEmpCount.map((skill) => {
                return (
                  
                    <div className="avatar-container">
                      <Avatar
                        label={skill.SkillName.slice(0, 1)}
                        className="p-overlay-badge avatar-content"
                       
                      >
                        <Badge value={skill.employeeCount} />
                      </Avatar>
                      <p className='skill-text'>{skill.SkillName}</p>
                    </div>
                  
                );
              })
            : "No Skills To Show"}
            </div>
        </Card>
        {/*******************/}
        {/* TOP Billing PROJECTS ******************/}
        {
          <div className="col-md-6 mb-3 pr-1">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-billing-proj"
            >
              <div className="p-card-title">
                <h3 className="dashboard-card-head">
                  {localization?.TopBillingProjects || "Top Billing Projects"}
                </h3>
              </div>
              <div className="dashbord-card-cont">
                {topBillingProjData?.length > 0 ? (
                  <TopBillingProjChart
                    data={topBillingProjData}
                    dateType={dateType}
                    date={date}
                  />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
                {topBillingProjData?.length > 0 && (
                  <div className="total-times">
                    {topBillingProjData?.map((val, i) => (
                      <ul key={i}>
                        <li>
                          {val.projectName} :{" "}
                          {ShowSecToHourAndMin(val.totalBill as number)}
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>
        }

        {/* TOP Tracking PROJECTS ******************/}
        {
          <div className="col-md-6 mb-3">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="top-tracking-proj"
            >
              <div className="p-card-title">
                <h3 className="dashboard-card-head">
                  {localization?.TopTrackingProjects || "Top Tracking Projects"}
                </h3>
              </div>
              <div className="dashbord-card-cont">
                {topTrackingProjData?.length > 0 ? (
                  <TopTrackingProjChart
                    data={topTrackingProjData}
                    dateType={dateType}
                    date={date}
                  />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    No data to show
                  </div>
                )}
                {topTrackingProjData?.length > 0 && (
                  <div className="total-times">
                    {topTrackingProjData?.map((val, i) => (
                      <ul key={i}>
                        <li>
                          {val.projectName} :{" "}
                          {ShowSecToHourAndMin(val?.totalTrack as number)}
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>
        }
      </div>
    </div>
  );
};
