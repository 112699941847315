import ReactApexChart from "react-apexcharts";
import { ProjBillTimeForReportSerDto } from "../../dtos/employeeBilling/ProjBillTimeForReportSerDto";
import { useEffect, useState } from "react";
import { TotalTrackAndBillTimeByProjDto } from "../../dtos/employeeBilling/TotalTrackAndBillTimeByProjDto";
import colors from '../../shared/json/Colors.json';

export const ProjectBillableTimeForReportChart = (props: any) => {
    const Data: TotalTrackAndBillTimeByProjDto[] = props?.data;
    const [showChart, setShowChart] = useState(false);
    const [chart, setChart] = useState<any>(null);
    const ser: ProjBillTimeForReportSerDto[] = [];

    const handleData = () => {
        Data?.forEach(proj => {
            const num = typeof proj?.BillingTimeInMinute === 'undefined' ? '0' : Number(proj?.BillingTimeInMinute as number / 60).toFixed(2);
            ser.push({
                x: proj?.ProjectName,
                y: Number(num)
            })
        })

        const updateChart: any = {

            series: [
                {
                    data: ser
                }
            ],
            options: {
                legend: {
                    show: false
                },
                chart: {
                    height: 350,
                    type: 'treemap',
                    animations: {
                        enabled: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                },
                colors: colors.colors,
                plotOptions: {
                    treemap: {
                        distributed: true,
                        enableShades: false
                    }
                }
            },
        };

        setChart(updateChart);
        setShowChart(true);
    }

    useEffect(() => {
        handleData();
    }, [Data])

    const height = 100 + chart?.series[0]?.data?.length * 50

    return (
        <div>
            {showChart && <ReactApexChart options={chart?.options} series={chart?.series} type="treemap" height={height} />}
        </div>
    )
}