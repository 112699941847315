import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useDispatch, useSelector } from "react-redux";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { ProjectItemsDto } from "../../dtos/project/ProjectItemsDto";
import { getProjectByUser } from "../../service/project/ProjectService";
import "../../css/components/report.css";
import { SiMicrosoftexcel } from "react-icons/si";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { DateRange } from "react-date-range";
import { EmployeeDto } from "../../dtos/employee/EmployeeDto";
import {
  getAllReport,
  getTotalTrackAndBillTime,
  getTotalTrackAndBillTimeByEmp,
  getTotalTrackAndBillTimeByProj,
} from "../../service/report/ReportServices";
import { InputForCreateOrUpdateEmployeeBillingDto } from "../../dtos/employeeBilling/InputForCreateOrUpdateEmployeeBillingDto";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import instance from "../../utills/BaseService";
import { SelectButton } from "primereact/selectbutton";
import { ShowMinToHourAndMin } from "../../shared/functions/ShowMinToHourAndMin";
import { FaRegFilePdf } from "react-icons/fa";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import { getEmployeeDetailsByAccess } from "../../service/employee/employeeService";
import { ProjectBillableTimeForReportChart } from "../charts/ProjectBillableTimeForReportChart";
import { ProjTrackAndBillTimeForReport } from "../charts/ProjTrackAndBillTimeForReport";
import { TotalTrackAndBillTimeByProjDto } from "../../dtos/employeeBilling/TotalTrackAndBillTimeByProjDto";
import { TotalTrackAndBillTimeByEmpDto } from "../../dtos/employeeBilling/TotalTrackAndBillTimeByEmpDto";
import { ProjectTrackTimeForReport } from "../charts/ProjectTrackTimeForReport";
import { EmployeeBillableTimeForReportChart } from "../charts/EmployeeBillableTimeForReportChart";
import { EmployeeTrackTimeForReportChart } from "../charts/EmployeeTrackTimeForReportChart";
import { ProgressSpinner } from "primereact/progressspinner";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { GetPrevFirstAndLastDayOfWeek } from "../../shared/functions/GetPrevFirstAndLastDayOfWeek";
import { GetNextFirstAndLastDayOfWeek } from "../../shared/functions/GetNextFirstAndLastDayOfWeek";
import { isDateValid } from "../../shared/functions/IsValidDateFunc";
import { GetAllReportInputDto } from "../../dtos/report/GetAllReportInputDto";
import { ProjectDetailsDto } from "../../dtos/employeeBilling/ProjectDetailsDto";

export const Report = () => {
  //#region all variables

  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const [reportData, setReportData] = useState<
    InputForCreateOrUpdateEmployeeBillingDto[]
  >([]);
  const [employee, setEmployee] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<
    EmployeeDto[] | null
  >(null);
  const [project, setProject] = useState<ProjectItemsDto[]>([]);
  const [selectedProject, setSelectedProject] = useState<any[] | null>(null);
  const [date, setDate] = useState<any>(new Date());
  const [calender, setCalender] = useState(
    <Calendar value={date} onChange={(e) => setDate(e.value)} />
  );
  const [employeeCode, setEmployeeCode] = useState<string[]>([]);
  const [employeeId, setEmployeeId] = useState<number[]>([]);
  const [projectDetails, setProjectDetails] = useState<ProjectDetailsDto[]>([]);
  const [excelBtn, setExcelBtn] = useState(true);
  const [pdfBtn, setPdfBtn] = useState(true);
  const [startDateForSheet, setStartDateForSheet] = useState<string>("");
  const [endDateForSheet, setEndDateForSheet] = useState<string>("");
  const [isChartVisible, setIsChartVisible] = useState<boolean>(false);
  const [dataForTotalTrackAndBill, setDataForTotalTrackAndBill] = useState<
    InputForCreateOrUpdateEmployeeBillingDto[]
  >([]);
  const [totalTrackByProj, setTotalTrackByProj] = useState<
    TotalTrackAndBillTimeByProjDto[]
  >([]);
  const [totalTrackByEmp, setTotalTrackByEmp] = useState<
    TotalTrackAndBillTimeByEmpDto[]
  >([]);
  const buttons: any[] = [
    { name: localization?.Day || "Day", value: "day" },
    { name: localization?.Week || "Week", value: "week" },
    { name: localization?.Month || "Month", value: "month" },
    { name: localization?.DateRange || "Date Range", value: "dateRange" },
  ];
  const [dateType, setDateType] = useState<string>(buttons[0].value);
  const [excelLoader, setExcelLoader] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef<any>(null);
  const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
  const [weekDate, setWeekDate] = useState<any>(new Date());
  const [monthDate, setMonthDate] = useState<any>(new Date());
  const [prevDate, setPrevDate] = useState<any>(new Date());
  const [dateRange, setDateRange] = useState<any>([new Date(), new Date()]);

  //#endregion

  //#region all Functions

  const getAllEmployees = async () => {
    const employees = await getEmployeeDetailsByAccess(dispatch);
    setEmployee(employees);
  };

  const getAllProjects = async () => {
    const projects = await getProjectByUser(dispatch);
    setProject(projects?.items);
  };

  const getFirstAndLastDayOfWeek = (date: any) => {
    const today = new Date(date); // Create a clone of the input date

    // Set the first day of the week (Monday)
    const firstDay = new Date(today);
    firstDay.setDate(
      today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
    );

    // Set the last day of the week (Sunday)
    const lastDay = new Date(firstDay);
    lastDay.setDate(firstDay.getDate() + 6);

    const weekDates = [firstDay, lastDay];
    setDate(weekDates); // Assuming setDate is a function you want to use to set the week dates
    setWeekDate(weekDates);
  };

  //after clicking the day button dates will set to day
  const handleDay = () => {
    setDate(prevDate);
  };

  //after clicking the month button dates will set to month
  const handleMonth = () => {
    setDate(monthDate);
  };

  //after clicking the week button dates will set to week that user will set before

  const handleWeek = () => {
    if (weekDate.length > 0) {
      const dt = [weekDate[0], weekDate[1]];
      setDate(dt);
    } else {
      const today = new Date(weekDate);
      const first = new Date(today);
      first.setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      );
      const last = new Date(today);
      last.setDate(first.getDate() + 6);
      const dateRange = [first, last];
      //setting the date tp previous week that user will set befor
      setDate(dateRange);
    }
  };

  //For handling date select component buttons
  const handleDate = (e: any) => {
    let dateTypeForPicker;
    if (e.value === null) {
      dateTypeForPicker = dateType;
      setDateType((prev: any) => {
        return prev;
      });
    } else {
      setDateType(e.value);
      dateTypeForPicker = e.value;
    }

    if (e.value === "day") {
      setShowDatePicker(false);
      handleDay();
    } else if (e.value === "week") {
      setShowDatePicker(false);
      // getFirstAndLastDayOfWeek([new Date()]);
      handleWeek();
    } else if (e.value === "month") {
      setShowDatePicker(false);
      handleMonth();
    } else if (e.value === "dateRange") {
      const defaultDates = [];
      defaultDates.push(new Date(), new Date());
      setDate(dateRange);
    }

    if (dateTypeForPicker === "dateRange") {
      setShowDatePicker(true);
    }
  };

  //For previous date button
  const handlePrevDate = (type: string, date: any) => {
    if (type === "day") {
      const previous = new Date(date?.getTime());
      previous.setDate(date?.getDate() - 1);
      setDate(previous);
      setPrevDate(previous);
    } else if (type === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay.getMonth() - 1);
      setDate(firstDay);
      setMonthDate(firstDay);
    } else if (type === "week" || type === "dateRange") {
      const daysOfWk = GetPrevFirstAndLastDayOfWeek(date);
      const dateRange = [];
      dateRange.push(daysOfWk.first, daysOfWk.last);
      setDate(dateRange);
      setWeekDate(dateRange);
    }
  };

  //For next date button
  const handleNextDate = (type: string, date: any) => {
    if (type === "day") {
      const next = new Date(date?.getTime());
      next.setDate(date?.getDate() + 1);
      setDate(next);
      setPrevDate(next);
    } else if (type === "month") {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDay.setMonth(firstDay?.getMonth() + 1);
      setDate(firstDay);
      setMonthDate(firstDay);
    } else if (type === "week" || type === "dateRange") {
      const daysOfWk = GetNextFirstAndLastDayOfWeek(date);
      const dateRange = [];
      dateRange.push(daysOfWk.first, daysOfWk.last);
      setDate(dateRange);
      setWeekDate(dateRange);
    }
  };

  //This function will execute when user select dates from date range calender which will show after clicking the date range button
  const handleDateRange = (item: any) => {
    const dateRan = [];
    dateRan.push(item?.selection?.startDate, item?.selection?.endDate);
    setDate(dateRan);
    setDateRange(dateRan);
  };

  //This function will create an array of selected employee's id OR selected projects's id
  const createEmpArr = (arr: EmployeeDto[]) => {
    const empCode: string[] = [];
    const empId: number[] = [];
    arr?.forEach((e: EmployeeDto) => {
      empCode.push(e?.employeeCode);
      empId.push(e.id);
    });
    return {
      empCode: empCode,
      emdId: empId,
    };
  };
  const createProjArr = (arr: any[]) => {
    const data: ProjectDetailsDto[] = [];
    arr?.forEach((e: any) => {
      data.push({
        id: e.id,
        name: e.name,
      });
    });

    return data;
  };

  //This function will give first and last day of month from a selected single date
  function getFirstAndLastDatesOfMonth(inputDate: Date) {
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    return {
      firstDay,
      lastDay,
    };
  }

  
  //This function will tell when the excel button will disabled or not
  const disableButton = (data: any) => {
    console.log(data)
    if (data?.length > 0) {
      setExcelBtn(false);
      setPdfBtn(false);
    } else {
      setExcelBtn(true);
      setPdfBtn(true);
    }
  };

  // This function is for handle the main API for table.....
  const handleData = async () => {
    setDisableSelectBtn(true);
    let empCode: string[] = [];
    let projID: ProjectDetailsDto[] = [];
    let employeeId: number[] = [];
    let startDate: string = "";
    let endDate: string = "";
    let validDateOrNot: boolean[] = [];

    if (Array.isArray(date)) {
      date.map((val) => {
        const isValidDate = isDateValid(val);
        validDateOrNot.push(isValidDate);
      });
    } else {
      const isValidDate = isDateValid(date);
      validDateOrNot.push(isValidDate);
    }

    if (!validDateOrNot.includes(false)) {
      //If there is starting and ending dates
      if (date?.length > 0) {
        const allStrDates = ISTToDate(date);
        setStartDateForSheet(allStrDates[0]);
        setEndDateForSheet(allStrDates[1]);
        startDate = allStrDates[0];
        endDate = allStrDates[1];
      }
      //If there is only a single date and Day button is selected
      else if (date?.length === undefined && dateType === "day") {
        const strDate = ISTToDate([date]);
        const tomorrow = new Date(date);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const nextDate = ISTToDate([tomorrow]);
        setStartDateForSheet(strDate[0]);
        setEndDateForSheet(nextDate[0]);
        startDate = strDate[0];
        endDate = nextDate[0];
      }
      //If there is only a single date and Month button is selected
      else if (date?.length === undefined && dateType === "month") {
        const firstAndLastDate = getFirstAndLastDatesOfMonth(date);
        const dt = [];
        dt.push(firstAndLastDate.firstDay, firstAndLastDate.lastDay);
        const strDate = ISTToDate(dt as Date[]);
        setStartDateForSheet(strDate[0]);
        setEndDateForSheet(strDate[1]);
        startDate = strDate[0];
        endDate = strDate[1];
      }

      if (
        (selectedEmployee?.length === 0 && selectedProject?.length === 0) ||
        (selectedEmployee?.length === 0 && selectedProject === null) ||
        (selectedEmployee === null && selectedProject?.length === 0)
      ) {
        setReportData([]);
        setDataForTotalTrackAndBill([]);
        setTotalTrackByProj([]);
        setTotalTrackByEmp([]);
        setIsChartVisible(false);
        setDisableSelectBtn(false);
        disableButton([])
        return false;
      }

      //If there is either employee selected or project selected then the API will execute
      if (selectedEmployee !== null || selectedProject !== null) {
        if (selectedEmployee !== null && selectedEmployee?.length > 0) {
          const emp = createEmpArr(selectedEmployee);
          empCode = emp.empCode;
          employeeId = emp.emdId;
        }
        if (selectedProject !== null && selectedProject?.length > 0) {
          const proj = createProjArr(selectedProject);
          projID = proj;
        }
        const allDetails: GetAllReportInputDto = {
          employeeId: employeeId,
          empCode: empCode,
          projectDetails: projID,
          startDate: startDate,
          endDate: endDate,
        };

        setEmployeeCode(allDetails.empCode);
        setEmployeeId(allDetails.employeeId);
        setProjectDetails(allDetails.projectDetails);
        // if (empCode.length > 0 || projID.length > 0) {

        const allPromises = [
          getAllReport(dispatch, allDetails),
          getTotalTrackAndBillTime(dispatch, allDetails),
          getTotalTrackAndBillTimeByProj(dispatch, allDetails),
          getTotalTrackAndBillTimeByEmp(dispatch, allDetails),
        ];

        const [res, totalTrackBill, totalTrackByProject, totalTrackByEmployee] =
          await Promise.all(allPromises);

        if (res?.length > 0) {
          setReportData(res);
        } else {
          setReportData([]);
        }

        disableButton(res);

        setDataForTotalTrackAndBill(totalTrackBill);
        setTotalTrackByProj(totalTrackByProject);
        setTotalTrackByEmp(totalTrackByEmployee);
      }
    } else {
      setReportData([]);
    }
    // }
    setDisableSelectBtn(false);
  };

  //This is for Excel Button when it will convert the blob to excel and download it.
  const handleExcelData = async () => {
    setExcelLoader(true);
    setExcelBtn(true);
    try {
      const Input: GetAllReportInputDto = {
        empCode: employeeCode,
        employeeId: employeeId,
        projectDetails: projectDetails,
        startDate: startDateForSheet,
        endDate: endDateForSheet,
      };
      const headers = {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        access_token: "Bearer " + localStorage.getItem("token"),
      };

      const response = await instance.post(
        "/report/getReportExcelData",
        Input,
        {
          headers,
          responseType: "blob",
        }
      );

      if (response) {
        setExcelLoader(false);
        setExcelBtn(false);
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Report");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };
  //This is for Excel Button when it will convert the blob to excel and download it.
  const handlePDFData = async () => {
    setPdfLoader(true);
    setPdfBtn(true);
    try {
      const Input: GetAllReportInputDto = {
        empCode: employeeCode,
        employeeId: employeeId,
        projectDetails: projectDetails,
        startDate: startDateForSheet,
        endDate: endDateForSheet,
      };
      const headers = {
        Accept: "application/pdf",
        access_token: "Bearer " + localStorage.getItem("token"),
      };
      const response = await instance.post("/report/getReportPDFData", Input, {
        responseType: "blob",
        headers,
      });
      if (response) {
        setPdfLoader(false);
        setPdfBtn(false);
      }

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Report.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  //This function will decide which calender will show according to the dateType
  const handleCalender = () => {
    if (dateType === "day") {
      setCalender(
        <DatePicker selected={date} onChange={(e) => setDate(e as Date)} />
      );
      setPrevDate(date);
    } else if (dateType === "month") {
      setCalender(
        <DatePicker
          selected={date}
          customInput={<CustomInput />}
          value={date}
          onChange={(e: any) => setDate(e)}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
        />
      );
      setMonthDate(date);
    } else if (dateType === "week") {
      setCalender(
        <DatePicker
          selectsRange={date}
          customInput={<CustomInput />}
          startDate={date[0]}
          endDate={date[1]}
          onChange={(e: any) => getFirstAndLastDayOfWeek(e)}
          dateFormatCalendar="MMMM yyyy"
        />
      );
    } else if (dateType === "dateRange") {
      // setCalender(<DatePicker selectsRange={date} disabled onChange={(e:any)=>setDate(e)}
      // dateFormatCalendar="MMMM yyyy" />)
    }
  };

  const employeeOptions = employee?.map((emp) => ({
    label: `${emp.fullName} (${emp.employeeCode})`,
    value: emp, // You can set the entire employee object as the value if needed
  }));

  //#endregion

  //#region all Templates

  const startToolbar = () => {
    return (
      <div className="report-start-tool">
        <SelectButton
          value={dateType}
          onChange={(e) => handleDate(e)}
          optionLabel="name"
          options={buttons}
          disabled={disableSelectBtn}
        />

        {/* date range calender */}

        {showDatePicker && (
          <div
            ref={datePickerRef}
            className="date-range report_date_range"
            style={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          >
            <DateRange
              onChange={(item) => handleDateRange(item)}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={[
                {
                  startDate: date?.length > 0 ? date[0] : date,
                  endDate: date?.length > 0 ? date[1] : date,
                  key: "selection",
                },
              ]}
              direction="horizontal"
            />
          </div>
        )}

        {/* visible input calender */}

        {dateType === "dateRange" ? (
          <div className="custom_date_range report_date_range">
            {String(date[0].getDate()).padStart(2, "0")}/
            {String(date[0].getMonth() + 1).padStart(2, "0")}/
            {date[0].getFullYear()} -{" "}
            {String(date[1].getDate()).padStart(2, "0")}/
            {String(date[1]?.getMonth() + 1).padStart(2, "0")}/
            {date[1].getFullYear()}
          </div>
        ) : (
          <div className="date-input">
            <Button
              icon={<AiOutlineLeft />}
              onClick={() => handlePrevDate(dateType, date)}
              className="date-comp-prev-btn"
              disabled={disableSelectBtn}
            />
            {calender}

            <Button
              icon={<AiOutlineRight />}
              onClick={() => handleNextDate(dateType, date)}
              className="date-comp-next-btn"
              disabled={disableSelectBtn}
            />
          </div>
        )}
      </div>
    );
  };

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const endToolbar = () => {
    return (
      <div className="endToolbarPerformance endToolReport">
        <Button
          icon={<SiMicrosoftexcel size={20} />}
          onClick={handleExcelData}
          className="excel-btn"
          disabled={excelBtn}
        />
        {excelLoader && (
          <ProgressSpinner
            style={{ width: "20px", height: "20px" }}
            strokeWidth="7"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        <Button
          icon={<FaRegFilePdf size={20} />}
          onClick={handlePDFData}
          className="pdf-btn"
          disabled={pdfBtn}
          badgeClassName="pdf"
        />
        {pdfLoader && (
          <ProgressSpinner
            style={{ width: "20px", height: "20px" }}
            strokeWidth="7"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        )}
        <MultiSelect
          value={selectedEmployee}
          filter
          resetFilterOnHide={true}
          onChange={(e) => setSelectedEmployee(e.value)}
          options={employeeOptions}
          placeholder={localization?.SelectEmployees || "Select Employees"}
          className="w-full md:w-10rem custom-multiselect"
          style={{ width: "11rem" }}
          display="chip"
          onHide={handleData}
          panelStyle={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        />
        <MultiSelect
          value={selectedProject}
          filter
          resetFilterOnHide={true}
          onChange={(e) => setSelectedProject(e.value)}
          options={project}
          optionLabel="name"
          placeholder={localization?.SelectProjects || "Select Projects"}
          className="w-full md:w-10rem custom-multiselect"
          style={{ width: "11rem" }}
          display="chip"
          onHide={handleData}
          panelStyle={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        />
      </div>
    );
  };

  const trackTimeColumnTemplate = (
    rowData: InputForCreateOrUpdateEmployeeBillingDto
  ) => {
    return <span>{ShowMinToHourAndMin("", rowData.TrackTimeInMinute)}</span>;
  };

  const billingTimeColumnTemplate = (
    rowData: InputForCreateOrUpdateEmployeeBillingDto
  ) => {
    return <span>{ShowMinToHourAndMin("", rowData.BillingTimeInMinute)}</span>;
  };

  //#endregion

  //#region all UseEffects
  const handleClickOutside = (event: any) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    // Attach event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleData();
  }, [date, dateType, mode?.color]);

  useEffect(() => {
    handleCalender();
  }, [dateType, date, isChartVisible]);

  useEffect(() => {
    getAllEmployees();
    getAllProjects();
  }, []);

  //#endregion

  return (
    <div className="report">
      <Toolbar
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="mb-3"
      />
      <Card
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="mb-3"
      >
        <DataTable
          className="report-datatable performance-table audit-log-table"
          value={reportData}
          showGridlines
        >
          <Column
            field="EmployeeName"
            header={localization?.EmployeeName || "Employee Name"}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>
          <Column
            field="ProjectName"
            header={localization?.ProjectName || "Project Name"}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>
          <Column
            field="TaskName"
            header={localization?.TaskName || "Task Name"}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>
          <Column
            field="TrackTimeInMinute"
            header={localization?.TrackingTime || "Tracking Time"}
            body={trackTimeColumnTemplate}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>
          <Column
            field="BillingTimeInMinute"
            header={localization?.BillingTime || "Billing Time"}
            body={billingTimeColumnTemplate}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>
        </DataTable>
      </Card>

      {/* CHARTS **********************************/}

      <div className="report-charts">
        <div className="row">
          {
            <div className="col-lg-6">
              {/* Project Billable Time Chart ********/}
              <Card
                style={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
                className="proj-bill-time-report mb-3"
              >
                <div className="p-card-title">
                  <h3 className="dashboard-card-head mb-0">
                    {localization?.ProjectBillableTime ||
                      "Project Billable Time"}
                  </h3>
                </div>
                <div className="dashbord-card-cont">
                  {totalTrackByProj.length > 0 ? (
                    <ProjectBillableTimeForReportChart
                      data={totalTrackByProj}
                    />
                  ) : (
                    <div style={{ color: mode?.color }} className="no-data">
                      {localization?.NoDataToShow || "No data to show"}
                    </div>
                  )}
                </div>
              </Card>
            </div>
          }

          {/* Project Trackable Time Chart ********/}
          <div className="col-lg-6">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="proj-bill-time-report mb-3"
            >
              <div className="p-card-title">
                <h3 className="dashboard-card-head mb-0">
                  {localization?.ProjectTrackableTime ||
                    "Project Trackable Time"}
                </h3>
              </div>
              <div>
                <div className="dashbord-card-cont">
                  {totalTrackByProj.length > 0 ? (
                    <ProjectTrackTimeForReport data={totalTrackByProj} />
                  ) : (
                    <div style={{ color: mode?.color }} className="no-data">
                      {localization?.NoDataToShow || "No data to show"}
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>

          {/* Total Track And Bill Time of Projects Comparison Chart ******/}
          <div className="col-md-12">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="proj-bill-time-comp-report mb-3"
            >
              <div className="p-card-title">
                <h3 className="dashboard-card-head mb-0">
                  {localization?.TotalTrackAndBillTimeofProjects ||
                    "Total Track And Bill Time of Projects"}
                </h3>
              </div>
              <div className="dashbord-card-cont">
                {dataForTotalTrackAndBill.length > 0 ? (
                  <ProjTrackAndBillTimeForReport
                    data={dataForTotalTrackAndBill}
                  />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    {localization?.NoDataToShow || "No data to show"}
                  </div>
                )}
              </div>
            </Card>
          </div>

          {/* Employee Billable Time Chart ********/}
          <div className="col-lg-6">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="proj-bill-time-report mb-3"
            >
              <div className="p-card-title">
                <h3 className="dashboard-card-head mb-0">
                  {localization?.EmployeeBillableTime ||
                    "Employee Billable Time"}
                </h3>
              </div>
              <div className="dashbord-card-cont">
                {totalTrackByEmp.length > 0 ? (
                  <EmployeeBillableTimeForReportChart data={totalTrackByEmp} />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    {localization?.NoDataToShow || "No data to show"}
                  </div>
                )}
              </div>
            </Card>
          </div>

          {/* Employee Trackable Time Chart ********/}
          <div className="col-lg-6">
            <Card
              style={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              className="proj-bill-time-report mb-3"
            >
              <div className="p-card-title">
                <h3 className="dashboard-card-head mb-0">
                  {localization?.EmployeeTrackableTime ||
                    "Employee Trackable Time"}
                </h3>
              </div>
              <div className="dashbord-card-cont">
                {totalTrackByEmp.length > 0 ? (
                  <EmployeeTrackTimeForReportChart data={totalTrackByEmp} />
                ) : (
                  <div style={{ color: mode?.color }} className="no-data">
                    {localization?.NoDataToShow || "No data to show"}
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
