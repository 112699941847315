import { useEffect } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //primereact-theme
import "primereact/resources/primereact.min.css"; //primereact-core
import "primeflex/primeflex.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./utills/ProtectedRoute";
import "./App.css";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import instance from "./utills/BaseService";
import { useDispatch, useSelector } from "react-redux";
import { setLocalization } from "./store/slices/localizationSlice";
import { PerformanceTable } from "./components/performance/PerformanceTable";
import "primeicons/primeicons.css";
import { KpiMappings } from "./components/kpiMappings/KpiMappings";
import { EmployeeBilling } from "./components/employeeBilling/EmployeeBilling";
import { EditProfile } from "./components/editProfile/EditProfile";
import { KPITable } from "./components/KPI/KPITable";
import { ProjectBilling } from "./components/projectBilling/ProjectBilling";
import { Project } from "./components/project/Project";
import { Employee } from "./components/employee/Employee";
import { Dashboard } from "./components/dashboard/DashBoard";
import { Report } from "./components/report/Report";
import { PerformnaceView } from "./components/subComponents/PerformanceView";
import { EmailSettings } from "./components/settings/EmailSettings";
import ProtectAdminRoutes from "./utills/ProtectAdminRoutes";
import { SystemAdmin } from "./components/settings/SystemAdmin";
import { CronSettings } from "./components/settings/CronSettings";
import { ApplicationSettings } from "./components/settings/ApplicationSettings";
import { YourPerformance } from "./components/yourPerformance/YourPerformance";
import { ErrorBoundary } from "react-error-boundary";
import { UnhandledError } from "./components/error/UnhandledError";
import { PageNotFound } from "./components/error/PageNotFound";
import { MailTemplate } from "./components/settings/MailTemplate";
import { AppModeState } from "./dtos/common/AppModeState";
import { ThemeState } from "./dtos/common/ThemeState";
import { UserMappings } from "./components/userMappings/UserMappings";
import { PerformanceReport } from "./components/performanceReport/PerformanceReport";
import { AuditLog } from "./components/settings/AuditLog";
import "primeicons/primeicons.css";
import { CronJobData } from "./components/settings/CronJobData";
import { CronJobStatus } from "./components/settings/CronJobStatus";
import SubKpi from "./components/subKpi/SubKpi";
import SubKpiOptions from "./components/subKpiOptions/SubKpiOptions";
import Goals from "./components/goals/Goals";
import UserGoal from "./components/userGoal/UserGoal";
import UpdateGoalTable from "./components/goals/UpdateGoalTable";
import GoalTable from "./components/goals/GoalTable";
import SkillTable from "./components/skills/SkillTable";

function App() {
  const dispatch = useDispatch();
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const SettingsHandler = async () => {
    const setting = await instance.get("/configuration/getallappsetting");
    dispatch(setLocalization(setting.data.values));
  };

  useEffect(() => {
    SettingsHandler();
  }, []);

  return (
    <div className={`${modeName} ${themeName}`}>
      <ErrorBoundary fallback={<UnhandledError />}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          >
            <Route
              path="kpi"
              element={
                <ProtectAdminRoutes>
                  <KPITable />
                </ProtectAdminRoutes>
              }
            />
            <Route path="/option" element={<SubKpiOptions />} />
            <Route path="/kpi/:kpiId" element={<SubKpi />} />
            <Route path="/performance/*" element={<PerformanceTable />} />
            <Route path="/performance/:empId" element={<PerformnaceView />} />
            <Route
              path="kpiMappings"
              element={
                <ProtectAdminRoutes>
                  <KpiMappings />
                </ProtectAdminRoutes>
              }
            />
            <Route
              path="userMappings"
              element={
                <ProtectAdminRoutes>
                  <UserMappings />
                </ProtectAdminRoutes>
              }
            />
            <Route path="employeeBilling" element={<EmployeeBilling />} />
            <Route path="editprofile" element={<EditProfile />} />
            <Route path="projectBilling" element={<ProjectBilling />} />
            <Route path="project" element={<Project />} />
            <Route path="employee" element={<Employee />} />
            <Route path="yourPerformance" element={<YourPerformance />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="report" element={<Report />} />
            <Route path="ratingsReport" element={<PerformanceReport />} />
            <Route path="goals" element={<GoalTable />} />
            <Route path="userGoals" element={<Goals />} />
            <Route path="myGoals" element={<UserGoal />} />
            <Route path="updateGoal/:id" element={<UpdateGoalTable />} />
            <Route path="skills" element={<SkillTable />} />

            <Route
              path="applicationSettings"
              element={
                <ProtectAdminRoutes>
                  <ApplicationSettings />
                </ProtectAdminRoutes>
              }
            />
            <Route
              path="mailSettings"
              element={
                <ProtectAdminRoutes>
                  <EmailSettings />
                </ProtectAdminRoutes>
              }
            />
            <Route
              path="systemAdmin"
              element={
                <ProtectAdminRoutes>
                  <SystemAdmin />
                </ProtectAdminRoutes>
              }
            />
            <Route
              path="cronSettings"
              element={
                <ProtectAdminRoutes>
                  <CronSettings />
                </ProtectAdminRoutes>
              }
            />
            <Route
              path="mailTemplateSettings"
              element={
                <ProtectAdminRoutes>
                  <MailTemplate />
                </ProtectAdminRoutes>
              }
            />
            {/* <Route path='projectSettings' element={<ProtectAdminRoutes><ProjectSettings /></ProtectAdminRoutes>} /> */}
            <Route
              path="auditLog"
              element={
                <ProtectAdminRoutes>
                  <AuditLog />
                </ProtectAdminRoutes>
              }
            />
            <Route
              path="cronJobDataSettings"
              element={
                <ProtectAdminRoutes>
                  <CronJobData />
                </ProtectAdminRoutes>
              }
            />
            <Route
              path="cronJobStatusSettings"
              element={
                <ProtectAdminRoutes>
                  <CronJobStatus />
                </ProtectAdminRoutes>
              }
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
