import ReactApexChart from "react-apexcharts";
import color from '../../shared/json/Colors.json';
import { PerformanceYearlyReportDto } from "../../dtos/performance/PerformanceYearlyReportDto";
import { useSelector } from "react-redux";
import { AppMode } from "../../data/AppMode";
import { AppModeState } from "../../dtos/common/AppModeState";

interface Props {
    data: PerformanceYearlyReportDto[];
    date: Date
}

interface ISeries {
    name: string;
    data: number[];
}

export const EmpPerformanceReportYearlyChart = (props: Props) => {
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find((md) => md.name === modeName);
    const categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    let ser: ISeries[] = [];

    props.data.forEach(val => {
        if (!val.isKPIPresent) {

            const data: number[] = [];
            data.push(val.January === null ? 0 : val.January, val.February === null ? 0 : val.February, val.March === null ? 0 : val.March, val.April === null ? 0 : val.April, val.May === null ? 0 : val.May, val.June === null ? 0 : val.June, val.July === null ? 0 : val.July, val.August === null ? 0 : val.August, val.September === null ? 0 : val.September, val.October === null ? 0 : val.October, val.November === null ? 0 : val.November, val.December === null ? 0 : val.December)

            ser.push({
                name: val.Name,
                data: data
            })
        }
    });

    const legendColor:string[] = [];
    ser.forEach(s=>legendColor.push(mode?.color as string))
    
    const legendClrForX:string[] = []
    categories.forEach(cat=>legendClrForX.push(mode?.color as string))

    const chart: any = {

        series: ser,
        options: {
            chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: false
                }
            },
            colors: color?.colors,
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: categories,
                title: {
                    text: 'Month',
                    style: {
                        color: mode?.color
                    }
                },
                labels:{
                    style:{
                        colors:legendClrForX
                    }
                }
            },
            yaxis: {
                labels:{
                    style:{
                        colors:legendColor
                    }
                },
                title: {
                    text: 'Ratings',
                    style: {
                        color: mode?.color
                    }
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5
            }
        },


    };

    return (
        <div>
            <div className="p-card-title"><h5 className="m-0">Performance Report of {props.date.getFullYear()}</h5></div>
            <div className="dashbord-card-cont"><ReactApexChart options={chart.options} series={chart.series} type="line" height={350} /></div>
        </div>

    )
}